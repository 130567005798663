import { ChangeDetectionStrategy, Component } from '@angular/core'

@Component({
  selector: 'lib-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrl: './not-authorized.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotAuthorizedComponent {

}
