<h2 mat-dialog-title>{{'deleteUser' | translate}}</h2>
<mat-dialog-content>
  <p>{{'confirmDeleteUser' | translate: { customer: data.name} }}</p>
</mat-dialog-content>
<mat-dialog-actions class="flex content-end gap-2 me-3">
  <button mat-flat-button color="primary" [mat-dialog-close]="true">
    {{'action.submit' | translate}}
  </button>
  <button mat-flat-button mat-dialog-close>
    {{'cancel' | translate}}
  </button>
</mat-dialog-actions>
