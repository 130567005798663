import { CdkScrollable } from '@angular/cdk/scrolling'
import { Component, Inject } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { MatButton } from '@angular/material/button'
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog"


import { MatSuffix } from '@angular/material/form-field'
import { MatIcon } from '@angular/material/icon'
import { MatProgressSpinner } from '@angular/material/progress-spinner'
import { TranslatePipe } from '@ngx-translate/core'
import { Company, CompanyService } from '../../accounting'
import { CompanyChangeFormComponent } from "../company-change-form/company-change-form.component";

@Component({
  selector: 'lib-company-change-dialog',
  templateUrl: './company-change-dialog.component.html',
  styleUrls: ['./company-change-dialog.component.scss'],
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, MatButton, MatIcon, MatProgressSpinner, MatSuffix, TranslatePipe, CompanyChangeFormComponent]
})
export class CompanyChangeDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Company,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CompanyChangeDialogComponent>,
    private service: CompanyService
  ) {
  }

  companyForm: FormGroup = this.fb.group({
    name: this.fb.control(this.data.name, [Validators.required]),
  })

  processing = false

  cancel() {
    this.dialogRef.close()
  }

  change() {
    if (!this.data.id) return

    this.processing = true
    const value = this.companyForm.value
    this.service.setName(this.data.id, value.name).subscribe((data) => {
      this.processing = false
      this.dialogRef.close(data)
    })
  }
}
