import { Routes } from "@angular/router"
import { COMPANY_ADMIN, COMPANY_READ, COMPANY_WRITE } from "../auth/roles"
import { UserTabRouteData } from "./user-tab-route-data"

export const TAB_ROUTES: Routes = [
    { path: '', redirectTo: 'customer', pathMatch: 'full' },
    {
        path: 'customer',
        title: 'User - Customer',
        loadComponent: () => import('../customer/customer-list/customer-list.component').then(m => m.CustomerListComponent),
        data: new UserTabRouteData("User", [COMPANY_READ, COMPANY_WRITE, COMPANY_ADMIN])
    },
    {
        path: 'invitation',
        title: 'User - Invitation',
        loadComponent: () => import('../invite/inviation-list/invitation-list.component').then(m => m.InvitationListComponent),
        data: new UserTabRouteData("Invitation", [COMPANY_READ, COMPANY_WRITE, COMPANY_ADMIN])
    }
]

export function getSharedTabRoutes(): Routes {
    return TAB_ROUTES
}