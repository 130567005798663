import { Component, computed, input } from '@angular/core'
import { MatTableDataSource, MatTableModule } from "@angular/material/table"
import { MatProgressBar } from '@angular/material/progress-bar'
import { MatIcon } from '@angular/material/icon'
import { MatAnchor, MatButton } from '@angular/material/button'
import { RouterLink } from '@angular/router'
import { TranslatePipe } from '@ngx-translate/core'
import { BoardListComponent } from '../../../board-list/board-list.component'
import { LicenseInfo } from '../license.api'
import { LicenseDataProvider } from '../../voucher/provider/license-data-provider'
import { LicenseListDataProvider } from '../../voucher/provider/license-list-data-provider'

@Component({
  selector: 'lib-license-list',
  templateUrl: './license-list.component.html',
  styleUrls: ['./license-list.component.scss'],
  imports: [MatProgressBar, BoardListComponent, MatTableModule, MatIcon, MatButton, MatAnchor, RouterLink, TranslatePipe]
})
export class LicenseListComponent {

  displayedColumns: string[] = ['id', 'name', 'serial', 'active', 'model', 'ain']
  dataSource: MatTableDataSource<LicenseInfo> = new MatTableDataSource()
  reloading = false
  readonly provider = input.required<LicenseListDataProvider>()
  dataProvider = computed(() => new LicenseDataProvider(this.provider()))
}
