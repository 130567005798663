import { effect, inject, Injectable, signal } from '@angular/core'
import { GaActionEnum, GoogleAnalyticsService } from "@hakimio/ngx-google-analytics"
import { KEYCLOAK_EVENT_SIGNAL, KeycloakEvent, KeycloakEventType } from "keycloak-angular"
import { Subject } from "rxjs"

import Keycloak from 'keycloak-js'
import { ConsentService } from '../consent/consent.service'
import { HomeService } from '../home/home.service'
import { Principal } from './principal'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  principal = signal<Principal | undefined>(undefined)
  initials: string | null = null
  private readonly loginSubject = new Subject<boolean>()
  loginChanged = this.loginSubject.asObservable()
  private readonly keycloakSignal = inject(KEYCLOAK_EVENT_SIGNAL)

  constructor(
    private readonly keycloak: Keycloak,
    private readonly consent: ConsentService,
    private readonly home: HomeService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {

    effect(() => {
      const keycloakEvent = this.keycloakSignal()

      this.handleKeycloakEvent(keycloakEvent)
    })

  }

  private updatePrincipal() {
    try {
      const token = this.keycloak.tokenParsed
      if (token == null) {
        this.clearPrincipal()
      } else {
        this.setPrincipal(token)
      }
    } catch (e) {
      console.log('Failed to load user details', e)
      this.clearPrincipal()
    }
  }

  public hasRole(role: string): boolean {
    if (this.principal() == null) return false
    return this.principal()!.roles.find(r => r == role) != null
  }

  public logout() {
    this.keycloak.logout().then()
  }

  login() {
    this.keycloak.login().then()
    this.googleAnalyticsService.event(GaActionEnum.LOGIN, {
      category: 'login_page',
      label: 'Login'
    })
  }

  getIdToken(): string | undefined {
    return this.keycloak.idToken
  }


  getToken(): string | undefined {
    return this.keycloak.token
  }

  public isLoggedIn(): boolean {
    return this.principal() != null
  }

  public getPrincipal(): Principal | undefined {
    return this.principal()
  }

  private clearPrincipal() {
    this.principal.set(undefined)
    this.initials = null
    this.consent.handlePrincipalChanged(this.principal())
    this.home.handlePrincipalChanged(this.principal())
    this.loginSubject.next(false)
  }

  private setPrincipal(token: any) {
    const id = token["sub"]
    const email = token["email"]
    const username = token["preferred_username"]
    const given_name = token["given_name"]
    const family_name = token["family_name"]
    const roles = token["realm_access"]["roles"]

    this.principal.set(new Principal(id, email, username, given_name, family_name, roles))
    this.consent.handlePrincipalChanged(this.principal())
    this.home.handlePrincipalChanged(this.principal())

    this.initials = [this.principal()!.given_name.charAt(0).toUpperCase(), this.principal()!.family_name.charAt(0).toUpperCase()].join('')
    this.loginSubject.next(true)
  }
  private handleKeycloakEvent(evt: KeycloakEvent) {
    console.log("Keycloak Event: " + evt.type)
    switch (evt.type) {
      case KeycloakEventType.AuthLogout:
      case KeycloakEventType.AuthRefreshError:
      case KeycloakEventType.AuthError:
        this.clearPrincipal()
        break
      case KeycloakEventType.TokenExpired:
        this.handleOnTokenExpiredEvent()
        break
      case KeycloakEventType.AuthSuccess:
      case KeycloakEventType.AuthRefreshSuccess:
      case KeycloakEventType.ActionUpdate:
      case KeycloakEventType.Ready:
        this.updatePrincipal()
        break
    }
  }

  private handleOnTokenExpiredEvent() {
    const loggedIn = this.isLoggedIn()
    if (loggedIn) {
      this.keycloak.updateToken(30).then()
    } else {
      this.clearPrincipal()
    }
  }
}
