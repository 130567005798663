import { CdkScrollable } from '@angular/cdk/scrolling'
import { Component, Inject } from '@angular/core'
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms"
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog"


import { MatButton } from '@angular/material/button'
import { MatFormField, MatLabel } from '@angular/material/form-field'
import { MatInput } from '@angular/material/input'
import { Customer, CustomerService } from '@mybitzer-workspace/shared'
import { TranslatePipe } from '@ngx-translate/core'

@Component({
  selector: 'app-customer-change-dialog',
  templateUrl: './customer-change-dialog.component.html',
  styleUrls: ['./customer-change-dialog.component.scss'],
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatDialogActions, MatButton, MatDialogClose, TranslatePipe]
})
export class CustomerChangeDialogComponent {

  form: FormGroup | undefined

  constructor(
    private fb: FormBuilder,
    private service: CustomerService,
    private dialogRef: MatDialogRef<CustomerChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Customer
  ) {
    this.form = this.fb.group({
      name: this.fb.control(data.name, [Validators.required]),
    })
  }

  save() {
    if (!this.form || !this.form.valid) return

    const values = this.form.value
    const name = values.name
    this.service.setName(this.data.id, name).subscribe({
      next: value => this.dialogRef.close(true),
      error: err => this.dialogRef.close(false)
    })
  }
}
