import { HttpEvent } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { Observable } from "rxjs"
import { AuthService } from "../auth/auth.service"
import { IMAGE_WRITE } from "../auth/roles"

import { BaseService } from "../base.service"
import { Image } from "./image-api"

@Injectable({
  providedIn: 'root'
})
export class ImageService extends BaseService {

  constructor(
    private authService: AuthService
  ) {
    super('image')
    this.retryCount = 1
  }

  upload(itemId: number, file: FormData): Observable<HttpEvent<Image>> {
    const url = this.createUrl('by/item/' + itemId)
    return this.http.post<Image>(url, file, {
      reportProgress: true,
      observe: 'events'
    })
  }

  downloadImage(itemId: number, id: number) {
    const path = itemId + '/download/' + id
    return this.download(path)
  }

  findByItemId(itemId: number): Observable<Image> {
    return this.get('by/item/' + itemId)
  }

  hasUploadPermissions(): boolean {
    return this.authService.hasRole(IMAGE_WRITE)
  }
}
