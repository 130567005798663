import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { map, Observable, of } from "rxjs"

import { TranslateService } from "@ngx-translate/core"
import { HotToastService } from "@ngxpert/hot-toast"
import { BaseService } from '../../base.service'
import { Page } from "../../shared.api"
import {
  License,
  LicenseInfo,
  LicenseModel,
  LicensePolicy,
  LicenseSearchRequest
} from './license.api';

@Injectable({
  providedIn: 'root'
})
export class LicenseService extends BaseService {
  constructor(
    private toast: HotToastService,
    private translate: TranslateService
  ) {

    super('accounting/license')
    this.retryCount = 1
  }

  search(request: LicenseSearchRequest, page: number, size: number): Observable<Page<LicenseInfo>> {
    return this.postPaged('search', request, page, size)
  }

  getAllLicenses(page: number, size = 20): Observable<Page<License>> {
    return this.getPaged<License>('', page, size)
  }

  getAllLicenseInfo(page: number, size = 20): Observable<Page<LicenseInfo>> {
    return this.getPaged<LicenseInfo>('info', page, size)
  }

  getFilteredLicenseInfo(page: number, size = 20, filter: string): Observable<Page<LicenseInfo>> {
    let params = new HttpParams()
    params = params.append('filter', filter)
    return this.getPaged<LicenseInfo>('info/filter', page, size, params)
  }

  private modelCache: Map<number, LicenseModel> = new Map<number, LicenseModel>()

  getLicenseModel(licenseModelId: number): Observable<LicenseModel> {
    const hit = this.modelCache.get(licenseModelId)
    if (hit) {
      return of(hit)
    }

    const observable = this.get<LicenseModel>('model/' + licenseModelId)
    return observable.pipe(
      map(m => {
        this.modelCache.set(licenseModelId, m)
        return m
      })
    )
  }

  getLicensePolicies() {
    return this.get<LicensePolicy[]>('policy')
  }

  createLicense(equipmentId: number, policyId: number): Observable<License> {
    const url = '' + equipmentId + '/policy/' + policyId
    return this.post(url, {})
  }

  activateLicense(equipmentId: number): Observable<License> {
    const url = '' + equipmentId + '/activate'
    return this.put(url, {})
  }

  upgradeLicense(equipmentId: number, policyId: number): Observable<License> {
    return this.post('upgrade/' + equipmentId + '/policy/' + policyId, {})
  }

  findByEquipment(equipmentId: number): Observable<LicenseInfo> {
    const url = 'find/by/equipment/' + equipmentId
    return this.get(url)
  }

  findByEquipmentsAndCompany(equipmentIds: number[], companyId: number): Observable<LicenseInfo[]> {
    const url = 'find/by/equipments/and/company/' + companyId
    return this.post(url, equipmentIds)
  }

  hasBPlusLicense(license: LicenseInfo | undefined): boolean {
    if (!license || license.model.title != "B-Plus") {
      this.toast.warning(this.translate.instant('licenseWarning'))
      return false
    }
    return license.model.title == "B-Plus"
  }
}
