<div class="flex flex-col justify-center mw-100">
  @if (reloading) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
  <mat-table [dataSource]="dataSource" class="grow shrink basis-auto">

    <ng-container matColumnDef="reference">
      <mat-header-cell *matHeaderCellDef>{{'voucher.Table.Reference' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.reference}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef>{{'voucher.Table.Amount' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let row">
        @if (row.amount >= 0) {
          <button mat-button color="primary">{{row.amount}} B-Coins</button>
        }
        @if (row.amount < 0) {
          <button mat-button color="warn">{{row.amount}} B-Coins</button>
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>{{'voucher.Table.Status' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.status}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-flat-button (click)="showReleaseVoucherByLinkDialog(row)">
          <mat-icon>loyalty</mat-icon>
        </button>
        <button mat-flat-button (click)="showReleaseVoucherByMailDialog(row)">
          <mat-icon>mail</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="6">{{'license.Table.NoData' | translate}}</td>
    </tr>

  </mat-table>
  <mat-paginator [length]="resultsLength" [pageSize]="pageSize"></mat-paginator>
</div>
