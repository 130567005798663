<div class="flex flex-col justify-center mw-100">
  @if (reloading) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
  <table mat-table [dataSource]="dataSource" class="grow shrink basis-auto">
    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef>{{'timestamp' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.timestamp | date:  'medium' }} </td>
    </ng-container>

    <ng-container matColumnDef="reference">
      <th mat-header-cell *matHeaderCellDef>{{'transaction.Table.Reference' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.reference}} </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>{{'amount' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        @if (row.amount >= 0) {
          <button mat-button color="primary">{{row.amount}} B-Coins</button>
        }
        @if (row.amount < 0) {
          <button mat-button color="warn">{{row.amount}} B-Coins</button>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="orderNr">
      <th mat-header-cell *matHeaderCellDef>{{'transaction.Table.OrderNr' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.orderNr}}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{'type' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.type}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="5">{{'transaction.Table.NoData' | translate}}</td>
    </tr>
  </table>
  <mat-paginator [length]="resultsLength" [pageSize]="pageSize"></mat-paginator>
</div>
