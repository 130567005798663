import { Injectable } from '@angular/core'
import { HttpParams } from "@angular/common/http"
import {
  Company,
  CompanyContact,
  CompanyContactChangeRequest,
  CompanyProfile,
  CompanyProfileChangeRequest,
  CompanyValidationResult
} from "./company.api"
import { Observable } from "rxjs"
import { FormBuilder, FormGroup } from "@angular/forms"
import { BaseService } from '../../base.service'
import { Page, PatchRequest } from '../../shared.api'

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService {

  constructor() {
    super('company')
    this.retryCount = 0
  }

  getCompany(companyId: number): Observable<Company> {
    return this.get('' + companyId)
  }

  getOwnCompany(): Observable<Company> {
    return this.get('')
  }

  getProfile(id: number): Observable<CompanyProfile> {
    return this.get('' + id + '/profile')
  }

  updateProfile(id: number, request: CompanyProfileChangeRequest): Observable<CompanyProfile> {
    return this.put('' + id + '/profile', request)
  }

  createProfileForm(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(''),
      street: fb.control(''),
      zip: fb.control(''),
      city: fb.control(''),
      country: fb.control(''),
      state: fb.control(''),
      phoneNumber: fb.control(''),
      website: fb.control(''),
    })
  }

  getContact(id: number): Observable<CompanyContact> {
    return this.get('' + id + '/contact')
  }

  updateContact(id: number, request: CompanyContactChangeRequest): Observable<CompanyContact> {
    return this.put('' + id + '/contact', request)
  }

  createContactForm(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(('')),
      title: fb.control(''),
      firstName: fb.control(''),
      lastName: fb.control(''),
      phoneNumber: fb.control(''),
      email: fb.control('')
    })
  }

  setName(id: number, name: string): Observable<Company> {
    return this.put('' + id + '/name', new PatchRequest(name))
  }
}
