export interface PropertyDouble {
  timestamp: string,
  value: number,
  metadata: PropertyMetadata
}

export interface PropertyDoubleEdges {
  previous: PropertyDouble[]
  next: PropertyDouble[]
}

export interface PropertyText {
  timestamp: string,
  value: string,
  metadata: PropertyMetadata
}

export interface PropertyMetadata {
  name: string,
  source: ThingValueSource
}

export interface ThingValueSource {
  thingId: number,
  thingTypeId: number,
  origin: string,
  text: string,
  inputType: string
}

export interface EnvelopePoint {
  x: number,
  y: number
}

export interface EnvelopePropertyEntry {
  x: PropertyDouble,
  y: PropertyDouble
}

export enum PropertyName {
  "BDN.Capacity" = 0,
  "BDN.CondTemp" = 1,
  "BDN.EvapTemp" = 2,
  "BDN.OilDiscGasTemp" = 3
}

export enum SiUnit {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT',
  BAR = 'BAR',
  PSI = 'PSI'
}

export interface EnvelopeInfo {
  type: SiUnit,
  points: EnvelopePoint[]
}

export class PropertySearchRequest {
  constructor(
    public from: string,
    public to: string,
    public names: string[]
  ) {
  }
}

export interface PropertyEntry {
  property: PropertyDouble,
  definition: PropertyDefinitionInfo
}

export interface PropertyDefinitionInfo {
  id: number,
  name: string,
  hasHistory: boolean,
  type: string,
  unitOfMeasure: UnitOfMeasure,
  unitOfMeasureName: string
}

export enum UnitOfMeasure {
  UNIT_C = 'UNIT_C',

  UNIT_HH = 'UNIT_HH',

  UNIT_SS = 'UNIT_SS',

  UNIT_BAR = 'UNIT_BAR',

  UNIT_KW = 'UNIT_KW',

  UNIT_HZ = 'UNIT_HZ',

  UNIT_V = 'UNIT_V',

  UNIT_W = 'UNIT_W',

  UNIT_H1 = 'UNIT_H1',

  UNIT_MA = 'UNIT_MA',

  UNIT_MM = 'UNIT_MM',

  UNIT_D1 = 'UNIT_D1',

  UNIT_YY = 'UNIT_YY',

  UNIT_BAR_ABS = 'UNIT_BAR_ABS',

  UNIT_BPS = 'UNIT_BPS',

  UNIT_PCT = 'UNIT_PCT',

  UNIT_MS = 'UNIT_MS',

  UNIT_DD = 'UNIT_DD',

  UNIT_OHM = 'UNIT_OHM',

  UNIT_MI = 'UNIT_MI',

  UNIT_SEC_10PCT = 'UNIT_SEC_10PCT',

  UNIT_A = 'UNIT_A',

  UNIT_K = 'UNIT_K',

  UNIT_HPA = 'UNIT_HPA',

  NONE = 'NONE',
}