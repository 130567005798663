import { Component, inject } from '@angular/core'
import { MatButton } from '@angular/material/button'
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogTitle } from "@angular/material/dialog"
import { MatDivider } from '@angular/material/divider'
import { MatIcon } from '@angular/material/icon'
import { TranslatePipe } from '@ngx-translate/core'
import { FileUploadLogEntry } from "../file-upload/file-upload.api"

@Component({
  selector: 'lib-file-upload-details-dialog',
  templateUrl: './file-upload-details-dialog.component.html',
  styleUrl: './file-upload-details-dialog.component.scss',
  imports: [MatDialogTitle, MatDivider, MatIcon, MatDialogActions, MatButton, MatDialogClose, TranslatePipe]
})
export class FileUploadDetailsDialogComponent {
  data = inject<FileUploadLogEntry>(MAT_DIALOG_DATA)!

}
