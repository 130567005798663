import {Component, Inject} from '@angular/core'
import {MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from "@angular/material/dialog"
import {MatButton} from "@angular/material/button"
import {TranslatePipe} from "@ngx-translate/core"

export interface ConfirmDialogData {
  titleKey: string;
  messageKey: string;
  messageParams?: any;
  isDelete?: boolean;
}

@Component({
  selector: 'lib-confirm-dialog',
  imports: [MatDialogModule, MatButton, TranslatePipe],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) { console.log(this.data)}

  onCancel(): void {
    this.dialogRef.close(false)
  }

  onConfirm(): void {
    this.dialogRef.close(true)
  }
}
