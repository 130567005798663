import { Component } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { RouterLink } from '@angular/router'

@Component({
  selector: 'lib-page-not-found',
  templateUrl: './page-not-found.component.html',
  imports: [
    MatButtonModule,
    RouterLink
  ],
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
}
