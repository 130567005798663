import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog"

import { CdkScrollable } from '@angular/cdk/scrolling'

import { MatProgressBar } from '@angular/material/progress-bar'
import { MatFormField, MatLabel } from '@angular/material/form-field'
import { MatInput } from '@angular/material/input'
import { MatButton } from '@angular/material/button'
import { CdkCopyToClipboard } from '@angular/cdk/clipboard'
import { MatIcon } from '@angular/material/icon'
import { TranslatePipe } from '@ngx-translate/core'
import { Voucher } from '../voucher.api'
import { VoucherService } from '../voucher.service'

@Component({
  selector: 'lib-release-voucher-by-link-dialog',
  templateUrl: './release-voucher-by-link-dialog.component.html',
  styleUrls: ['./release-voucher-by-link-dialog.component.scss'],
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatProgressBar, MatFormField, MatLabel, MatInput, MatButton, CdkCopyToClipboard, MatIcon, MatDialogActions, TranslatePipe]
})
export class ReleaseVoucherByLinkDialogComponent {

  reloading = false
  link = ""

  constructor(
    public dialogRef: MatDialogRef<ReleaseVoucherByLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Voucher,
    private readonly service: VoucherService
  ) {
  }

  onCancelClick() {
    this.dialogRef.close()
  }

  onReleaseClick() {
    this.reloading = true
    this.service.releaseVoucherByLink(this.data.id).subscribe(link => this.showLink(link))
  }

  private showLink(link: string) {
    this.link = (link) || ""
    this.reloading = false
  }
}
