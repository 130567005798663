import { Inject, Injectable } from '@angular/core'
import Usercentrics, { BaseService } from '@usercentrics/cmp-browser-sdk'
import LogRocket from "logrocket"
import { CookieService } from 'ngx-cookie-service'
import { Principal } from "../auth/principal"
import { ENVIRONMENT } from '../environment.token'
import { ConsentCookie, ConsentEventSubType, ConsentEventType, ConsentServiceName } from "./consent.api"

@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  private readonly UC = new Usercentrics('UZKpRkXQt')
  private logRocketConsent = false
  private logRocketInitialized = false
  private principal: Principal | undefined = undefined
  private requiresAppReload = false

  constructor(@Inject(ENVIRONMENT) private readonly environment: any, private readonly cookieService: CookieService) {

  }

  init() {
    this.UC.init().then(_ => { this.initUC() })
    window.addEventListener(ConsentEventType.UC_UI_CMP_EVENT, this.handleUCEvent.bind(this))
  }

  private handleUCEvent(e: any) {
    switch (e.detail.type) {
      case ConsentEventSubType.SAVE:
      case ConsentEventSubType.ACCEPT_ALL:
        this.initUC()
        break
    }
  }

  private initUC() {
    const logRocket = this.getService(ConsentServiceName.LOG_ROCKET)
    if (logRocket) {
      this.updateLogRocket(logRocket.consent.status)
    }

    const google = this.getService(ConsentServiceName.GOOGLE_ANALYTICS)
    if (google) {
      this.updateGoogleAnalytics(google)
    }

    if (this.requiresAppReload) window.location.reload()
  }
  handlePrincipalChanged(principal: Principal | undefined) {
    this.principal = principal
    this.updateLogRocket(this.logRocketConsent)
  }

  openCookieSettings() {
    (window as any).UC_UI.showSecondLayer()
  }

  private updateGoogleAnalytics(service: BaseService) {
    const googleConsent = service.consent.status
    this.cookieService.set(ConsentCookie.GOOGLE_ANALYTICS, String(googleConsent))
    this.updateGoogleAnalyticsGlobalVariable(googleConsent)
  }

  private updateLogRocket(consent: boolean) {
    if (!this.environment.logrocket) return
    if (!this.logRocketConsent && !consent) return

    if (this.logRocketConsent && consent) {
      this.handleLogRocketPrincipal()
    } else if (!this.logRocketConsent && consent) {
      this.enableLogRocket()
      this.handleLogRocketPrincipal()
    } else {
      this.disableLogRocket()
    }

    this.logRocketConsent = consent
  }

  private enableLogRocket() {
    if (!this.logRocketInitialized) {
      LogRocket.init(this.environment.logrocketAppID)
      this.logRocketInitialized = true
    }
  }

  private disableLogRocket() {
    if (this.logRocketInitialized) {
      this.logRocketInitialized = false
      this.requiresAppReload = true
    }
  }

  private handleLogRocketPrincipal() {
    if (this.principal) {
      LogRocket.identify(this.environment.logrocketAppID, {
        name: this.principal.username,
        email: this.principal.email,
      })
    }
  }

  private getService(name: ConsentServiceName): BaseService | undefined {
    const uc_ui = this.UC?.getServicesBaseInfo()
    return uc_ui?.find((service: BaseService) => service.name == name)
  }

  initialConsent() {
    const cookieService = new CookieService(document, { platform: "browser" })

    const googleConsent = cookieService.get(ConsentCookie.GOOGLE_ANALYTICS) == "true"
    this.updateGoogleAnalyticsGlobalVariable(googleConsent)
  }

  private updateGoogleAnalyticsGlobalVariable(consent: boolean) {
    (window as any)[`ga-disable-${this.environment.googleAnalyticsId}`] = !consent
  }
}
