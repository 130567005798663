import { Directive, ElementRef, HostListener, input } from '@angular/core'

@Directive({ selector: '[libDefaultImage]' })
export class DefaultImageDirective {

  readonly defaultImage = input<string>('')


  constructor(private readonly elementRef: ElementRef) { }

  @HostListener('error')
  loadDefaultOnError() {
    const element: HTMLImageElement = this.elementRef.nativeElement
    element.src = this.defaultImage() || 'assets/img/logo-flat-black.png'
  }

}
