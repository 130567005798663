import { Location } from "@angular/common"
import { Component, input } from '@angular/core'
import { MatButton, MatIconButton } from '@angular/material/button'
import { MatDialog } from "@angular/material/dialog"
import { MatIcon } from '@angular/material/icon'
import { MatToolbar } from '@angular/material/toolbar'
import { TranslatePipe, TranslateService } from "@ngx-translate/core"
import { HotToastService } from "@ngxpert/hot-toast"
import { InviteDialogComponent } from '../../invite/invite-dialog/invite-dialog.component'
import { UserBoardService } from "../user-board.service"

@Component({
  selector: 'lib-user-board-header',
  templateUrl: './user-board-header.component.html',
  styleUrls: ['./user-board-header.component.scss'],
  imports: [MatToolbar, MatIconButton, MatIcon, MatButton, TranslatePipe]
})
export class UserBoardHeaderComponent {
  companyId = input<string | undefined>()

  constructor(
    private location: Location,
    public service: UserBoardService,
    private dialog: MatDialog,
    private toast: HotToastService,
    private translate: TranslateService
  ) {

  }


  back() {
    this.location.back()
  }

  showInvitationDialog() {
    const company = this.service.company
    if (!company) return
    const dialogRef = this.dialog.open(InviteDialogComponent, {
      width: '50%',
      data: {
        id: this.companyId()
      }
    })
    dialogRef.afterClosed().subscribe((sent: boolean) => {
      if (!sent) {
        this.toast.error(this.translate.instant('invitationFailed'))
        return
      }
      this.toast.info(this.translate.instant('invitationSent'))
    })
  }
}
