import { Observable } from "rxjs"
import { BoardListDataProvider } from "../../../board-list/board-list-data-provider"
import { LicenseInfo } from "../../license/license.api"
import { LicenseListDataProvider } from "./license-list-data-provider"
import { Page } from "../../../shared.api"


export class LicenseDataProvider implements BoardListDataProvider<LicenseInfo> {

  constructor(private readonly provider: LicenseListDataProvider) {
  }

  loadFilteredPage(filter: string, page: number, size: number): Observable<Page<LicenseInfo>> {
    return this.provider.getNextFilteredPage(page, size, filter)
  }

  loadPage(page: number, size: number): Observable<Page<LicenseInfo>> {
    return this.provider.getNextPage(page, size)
  }
}
