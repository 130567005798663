<h2 mat-dialog-title>{{'inviteNewUser' | translate}}</h2>
<mat-dialog-content>
  @if (form) {
    <form class="flex flex-col mt-3" [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>{{'contactFirstName' | translate}}</mat-label>
        <input type="text" matInput formControlName="firstName">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'contactLastName' | translate}}</mat-label>
        <input type="text" matInput formControlName="lastName">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'contactMail' | translate}}</mat-label>
        <input type="text" matInput formControlName="email">
      </mat-form-field>
    </form>
  }
</mat-dialog-content>
<mat-dialog-actions class="flex content-end gap-2 me-3">
  <button mat-flat-button color="primary" (click)="sendInvitation()">
    {{'action.submit' | translate}}
  </button>
  <button mat-flat-button mat-dialog-close>
    {{'cancel' | translate}}
  </button>
</mat-dialog-actions>
