import { Company } from "../accounting"
import { SearchRequest } from "../search/search.api"
import { Page } from "../shared.api"

export interface Customer {
  id: number,
  externalId: string,
  name: string,
  email: string,
  azureGuid?: string,
  companyId?: number
}

export interface CustomerSearchRequest extends SearchRequest {
  companyId: number | undefined
}

export interface CustomerSearchResponse {
  entries: Page<CustomerSearchResultEntry>
}

export interface CustomerSearchResultEntry {
  id: string,
  externalId: string,
  name: string,
  email: string,
}

export interface CustomerInfo extends Customer {
   company: Company,
   entitlement: Entitlement,
   subscription: Subscription
}

export interface Entitlement {
  id: number,
  userLevel: string,
  roles: string[]
}

export interface Subscription {
  id: number,
  types: string[]
}

export enum CustomerRole {
  POWER_USER = 'POWER_USER',
  BILLING_USER = 'BILLING_USER',
  SERVICE_USER = 'SERVICE_USER',
  WEBSHOP_USER = 'WEBSHOP_USER',
}

export const RoleTranslationMap: Record<CustomerRole, string> = {
  [CustomerRole.SERVICE_USER]: 'userRole_service',
  [CustomerRole.POWER_USER]: 'userRole_power',
  [CustomerRole.BILLING_USER]: 'userRole_billing',
  [CustomerRole.WEBSHOP_USER]: 'userRole_webshop'
}

export const CustomerRoles: string[] = Object.values(CustomerRole)

