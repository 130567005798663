<h2 mat-dialog-title>{{'company.Dialog.Change.Title' | translate}}</h2>
<mat-dialog-content class="mat-typography">
  <lib-company-change-form [readonly]="false" [form]="companyForm" />
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div mat-dialog-actions class="float-end p-3">
    <button mat-raised-button color="primary" (click)="change()" [disabled]="!companyForm.valid || processing">
      {{'action.save' | translate}}
      @if (processing) {
      <mat-icon>
        <mat-spinner matSuffix [diameter]="18"></mat-spinner>
      </mat-icon>
      }
    </button>
    <button mat-stroked-button (click)="cancel()"> {{'cancel' | translate}} </button>
  </div>

</mat-dialog-actions>