<mat-card>
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" color="accent">
    @for (link of navLinks; track link) {
      <a mat-tab-link
        #rla="routerLinkActive"
        [active]="rla.isActive"
        [routerLink]="link.path"
        routerLinkActive>
        {{link.label | translate}}
      </a>
    }
  </nav>
  <mat-divider></mat-divider>
  <mat-tab-nav-panel #tabPanel class="pb-3">
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</mat-card>
