import { Injectable } from '@angular/core'
import { BaseService, PatchRequest } from "@mybitzer-workspace/shared"
import { Observable } from "rxjs"
import { Entitlement } from './entitlement.api'

@Injectable({
  providedIn: 'root'
})
export class EntitlementService extends BaseService {

  constructor() {
    super('entitlement')
    this.retryCount = 0
  }


  addRole(customerId: number, role: string): Observable<Entitlement> {
    return this.put(customerId + '/role/add', new PatchRequest(role))
  }

  removeRole(customerId: number, role: string): Observable<Entitlement> {
    return this.put(customerId + '/role/remove', new PatchRequest(role))
  }

  setRoles(customerId: number, roles: string[]): Observable<Entitlement> {
    return this.put(customerId + '/role', new PatchRequest(roles))
  }

  setUserLevel(customerId: number, level: string): Observable<Entitlement> {
    return this.put(customerId + '/level', new PatchRequest(level))
  }

}
