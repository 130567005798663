import { Injectable } from '@angular/core'
import { Observable } from "rxjs"
import { BaseService } from '../../base.service'
import { Page } from '../../shared.api'
import { ChargeEntry } from "./charge.api"


@Injectable({
  providedIn: 'root'
})
export class ChargeService extends BaseService {

  constructor() {
    super('accounting/charge')
  }

  getAllChargeEntries(page: number, size = 20): Observable<Page<ChargeEntry>> {
    return this.getPaged<ChargeEntry>('', page, size)
  }
}
