import { BreakpointObserver } from "@angular/cdk/layout"
import { AsyncPipe, Location, SlicePipe } from "@angular/common"
import { Component, inject, input } from '@angular/core'
import { MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'
import { MatToolbar } from '@angular/material/toolbar'
import { MatTooltip } from '@angular/material/tooltip'
import { TranslatePipe } from '@ngx-translate/core'
import { Observable } from "rxjs"
import { map, shareReplay } from "rxjs/operators"

@Component({
  selector: 'lib-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss'],
  imports: [MatToolbar, MatIconButton, MatTooltip, MatIcon, AsyncPipe, SlicePipe, TranslatePipe]
})
export class HeaderToolbarComponent {
  private location = inject(Location)
  private responsive = inject(BreakpointObserver)


  readonly showBack = input<boolean>(true)
  readonly title = input<string>()
  readonly subtitleFirst = input<string>()
  readonly subtitleLast = input<string>()
  isHandset$: Observable<boolean> = this.responsive.observe(['(max-width: 1000px)'])
    .pipe(
      map(result => result.matches),
      shareReplay()
    )

  back() {
    this.location.back()
  }
}
