import { Component, Input, OnInit } from '@angular/core'
import { MatIconButton } from '@angular/material/button'
import { MatDialog } from "@angular/material/dialog"
import { MatIcon } from '@angular/material/icon'
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu'
import { EMPTY, filter, switchMap, tap } from 'rxjs'

import { TranslatePipe } from '@ngx-translate/core'
import { Company, CompanyService } from '../../accounting'
import { HeaderToolbarComponent } from '../../header-toolbar/header-toolbar.component'
import { HeaderToolbarActions } from '../../header-toolbar/header-toolbar.directives'
import { LoadingBarComponent } from '../../loading-bar/loading-bar.component'
import { CompanyChangeDialogComponent } from '../company-change-dialog/company-change-dialog.component'
import { CompanyDetailsContactComponent } from '../company-details-contact/company-details-contact.component'
import { CompanyDetailsProfileComponent } from '../company-details-profile/company-details-profile.component'

@Component({
  selector: 'lib-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
  imports: [HeaderToolbarComponent, HeaderToolbarActions, MatIconButton, MatMenuTrigger, MatIcon, CompanyDetailsProfileComponent, CompanyDetailsContactComponent, MatMenu, MatMenuItem, TranslatePipe, LoadingBarComponent]
})
export class CompanyDetailsComponent implements OnInit {
  @Input() id: string | undefined

  company: Company | undefined
  reloading = false

  constructor(private readonly service: CompanyService, private readonly dialog: MatDialog) {
  }

  ngOnInit() {
    this.reload().subscribe()

  }

  reload() {
    if (this.reloading) return EMPTY
    this.reloading = true
    const company$ = this.id ? this.service.getCompany(+this.id!) : this.service.getOwnCompany()
    return company$.pipe(
      tap({
        next: data => {
          this.company = data
          this.reloading = false
        },
        error: () => this.reloading = false
      })
    )
  }

  edit() {
    if (!this.company) return
    this.dialog.open(CompanyChangeDialogComponent, {
      width: '85%',
      data: this.company
    }).afterClosed().pipe(
      filter(result => !!result),
      switchMap(() => this.reload())
    ).subscribe()
  }
}
