@if (form()) {
  <form class="flex flex-col mt-3" [formGroup]="form()">
    <mat-form-field appearance="outline">
      <mat-label>{{'contact.title' | translate}}</mat-label>
      <input type="text" matInput formControlName="title" [readonly]="readonly()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'contact.firstName' | translate}}</mat-label>
      <input type="text" matInput formControlName="firstName" [readonly]="readonly()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'contact.lastName' | translate}}</mat-label>
      <input type="text" matInput formControlName="lastName" [readonly]="readonly()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'contact.phone' | translate}}</mat-label>
      <input type="text" matInput formControlName="phoneNumber" [readonly]="readonly()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'contact.email' | translate}}</mat-label>
      <input type="text" matInput formControlName="email" [readonly]="readonly()">
    </mat-form-field>
  </form>
}
