import { Injectable } from '@angular/core'
import { Observable } from "rxjs"

import { BaseService } from '../../base.service'
import { Page, PatchRequest } from '../../shared.api'
import { VoucherAccountSettings, VoucherAccountSettingsChangeRequest, VoucherAccountSettingsInfo } from './voucher-settings.api'
import { MailReceiver, Voucher, VoucherCreateRequest } from './voucher.api'

@Injectable({
  providedIn: 'root'
})
export class VoucherService extends BaseService {

  constructor() {
    super('accounting/voucher')
  }

  createVoucher(request: VoucherCreateRequest): Observable<Voucher> {
    return this.post('', request)
  }

  releaseVoucherByLink(voucherId: string): Observable<string> {
    return this.putText(voucherId + '/release/by/link', {})
  }

  releaseVoucherByMail(voucherId: string, receiver: MailReceiver[]): Observable<any> {
    return this.put(voucherId + '/release/by/mail', receiver)
  }

  getAllAccountSettings(page: number, size: number): Observable<Page<VoucherAccountSettings>> {
    return this.getPaged('settings', page, size)
  }

  getAllAccountSettingInfos(page: number, size: number): Observable<Page<VoucherAccountSettingsInfo>> {
    return this.getPaged('settings/info', page, size)
  }

  getAccountSettings(accountId: number): Observable<VoucherAccountSettings> {
    return this.get('settings/' + accountId)
  }

  updateAccountSettings(accountId: number, request: VoucherAccountSettingsChangeRequest): Observable<VoucherAccountSettings> {
    return this.put('settings/' + accountId, request)
  }

  getVouchers(accountId: number, pageNr: number, pageSize: number): Observable<Page<Voucher>> {
    return this.getPaged('find/by/account/' + accountId, pageNr, pageSize)
  }

  getAllVouchers(pageNr: number, pageSize: number): Observable<Page<Voucher>> {
    return this.getPaged('', pageNr, pageSize)
  }

  cashVoucher(hash: string): Observable<any> {
    return this.put('cash', new PatchRequest(hash))
  }
}
