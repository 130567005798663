import { Inject, Injectable, signal } from '@angular/core'
import packageJson from 'package.json'
import { Subject } from "rxjs"
import { Principal } from '../auth/principal'
import { ConsentService } from '../consent/consent.service'
import { HEADER_ACTIONS, MENU_ACTIONS } from "./config"
import { MainHeaderAction } from "./main-header-action"
import { MainMenuAction, MainMenuActionGroup } from "./main-menu-action"
import { IMenuService, Menu, MENU_SERVICE } from "./menu-api"

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  public version: string = packageJson.version
  menuActions = signal<MainMenuActionGroup[]>([])
  headerActions = signal<MainHeaderAction[]>([])
  customHeaderActions = signal<MainHeaderAction[]>([])
  title = signal<string>('')
  searchLabelText = signal<string>('')

  private readonly searchSubject = new Subject<string>()
  search = this.searchSubject.asObservable()
  query = ''
  searchVisible = false


  constructor(
    private readonly consentService: ConsentService,
    @Inject(MENU_SERVICE) private menuService: IMenuService
  ) {

  }

  openCookieSettings() {
    this.consentService.openCookieSettings()
  }

  handlePrincipalChanged(principal: Principal | undefined) {
    this.menuService.getMenu().subscribe({
      next: value => this.handleMenuData(value),
      error: () => this.handleFailed(principal)
    })
    this.headerActions.set(HEADER_ACTIONS.filter(a => this.isActionAccessible(principal, a)))
  }

  private handleMenuData(value: Menu) {
    this.menuActions.set(value?.groups)
  }

  private handleFailed(principal: Principal | undefined) {
    this.menuActions.set(MENU_ACTIONS.map(g => this.updateGroupAccessibility(principal, g)))
  }

  private updateGroupAccessibility(principal: Principal | undefined, g: MainMenuActionGroup): MainMenuActionGroup {
    const actions = g.actions.filter(a => this.isActionAccessible(principal, a))
    return { name: g.name, actions }
  }

  private isActionAccessible(principal: Principal | undefined, a: MainMenuAction | MainHeaderAction): boolean {
    if (a.roles.length == 0) return true
    if (!principal) return false
    return a.roles.find(role => principal.roles.find(r => r == role) != null) != null
  }

  executeSearch(query: string) {
    this.query = query
    this.searchSubject.next(query)
  }

  setTitle(title: string) {
    this.title.set(title)
  }

}
