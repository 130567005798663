export interface ResourceHierarchyNode {
  resource: ResourceReference,
  children: ResourceHierarchyNode[],
  url: string
}

export interface ResourceReference {
  id: number,
  name: string,
  externalId: string,
  ownerId: number,
  type: ResourceType
}

export enum ResourceType {
  UNKNOWN = 'UNKNOWN',
  SITE = 'SITE',
  SYSTEM = 'SYSTEM',
  EQUIPMENT = 'EQUIPMENT',
  COMPANY = 'COMPANY',
  OVERALL = 'OVERALL'
}

