import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { Observable } from "rxjs"
import { Company } from "../accounting"
import { AuthService } from "../auth/auth.service"
import { SHARING_ADMIN, SHARING_WRITE } from "../auth/roles"
import { BaseService } from "../base.service"
import { Page } from "../shared.api"
import {
  AccessEntryEquipment,
  AccessEntryItem,
  ShareChangeRequest,
  SiteTree,
  ValidateShareEdit,
  ValidationResult
} from "./access.api"

@Injectable({
  providedIn: 'root'
})
export class AccessService extends BaseService {
  roles = [SHARING_WRITE, SHARING_ADMIN]

  constructor(private readonly authService: AuthService) {
    super('access')
  }

  getAvailableForSites(siteId: number, page = 0, size = 10): Observable<Page<Company>> {
    return this.getPaged('site/' + siteId + '/available', page, size)
  }

  getSharedForSites(siteId: number, page = 0, size = 10): Observable<Page<Company>> {
    return this.getPaged('site/' + siteId + '/shared', page, size)
  }

  searchForSiteCompanies(itemId: number, query: string): Observable<Page<Company>> {
    const params = new HttpParams()
    params.append('query', query)

    return this.postPaged('site/' + itemId + '/search', { query }, 0, 10)
  }

  shareItems(itemId: number, request: ShareChangeRequest): Observable<AccessEntryItem> {
    return this.put('site/' + itemId, request)
  }

  getSiteTree(siteId: number): Observable<SiteTree> {
    return this.get('tree/site/' + siteId)
  }

  validate(request: ValidateShareEdit): Observable<ValidationResult> {
    return this.post('validate', request)
  }

  getAllItemAccessForCompany(itemId: number[], companyId: number): Observable<AccessEntryItem[]> {
    return this.post('item/company/' + companyId, itemId)
  }

  getAllEquipmentAccessForCompany(equipmentId: number[], companyId: number): Observable<AccessEntryEquipment[]> {
    return this.post('equipment/company/' + companyId, equipmentId)
  }

  getItemAccess(itemId: number): Observable<AccessEntryItem> {
    return this.get(`item/${itemId}/access`)
  }

  getEquipmentAccess(equipmentId: number): Observable<AccessEntryEquipment> {
    return this.get(`equipment/${equipmentId}/access`)
  }

  hasSharingEditPermissions(): boolean {
    return this.authService.hasRole(SHARING_WRITE) || this.authService.hasRole(SHARING_ADMIN)
  }
}
