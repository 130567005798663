
import { Injectable } from "@angular/core"
import { BehaviorSubject, EMPTY, tap } from "rxjs"
import { Company, CompanyService } from "../accounting"


@Injectable({
  providedIn: 'root'
})
export class UserBoardService {

  company: Company | undefined
  reloading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(private readonly service: CompanyService) {
  }


  reload(id: string | undefined) {
    if (this.reloading.value) return EMPTY
    this.reloading.next(true)
    const request = id ? this.service.getCompany(+id) : this.service.getOwnCompany()
    return request.pipe(
      tap(data => {
        this.company = data
        this.reloading.next(false)
      })
    )
  }
}
