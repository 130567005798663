import { Component, OnInit } from '@angular/core'
import { MatCard } from '@angular/material/card'
import { MatTabLink, MatTabNav, MatTabNavPanel } from '@angular/material/tabs'
import { ActivatedRoute, RouterLink, RouterLinkActive, RouterOutlet, Routes } from "@angular/router"

import { MatDivider } from '@angular/material/divider'
import { TranslatePipe } from '@ngx-translate/core'
import { NavLink } from '../../home'

@Component({
  selector: 'lib-user-board-tabs',
  templateUrl: './user-board-tabs.component.html',
  styleUrls: ['./user-board-tabs.component.scss'],
  imports: [MatCard, MatTabNav, MatTabLink, RouterLinkActive, RouterLink, MatDivider, MatTabNavPanel, RouterOutlet, TranslatePipe]
})
export class UserBoardTabsComponent implements OnInit {
  navLinks: NavLink[] = []

  constructor(private readonly route: ActivatedRoute) {
  }

  ngOnInit() {
    this.updateNavigation()
  }

  private updateNavigation() {
    const config = this.route.routeConfig
    const children = config?.children
    this.navLinks = children ? this.buildNavItems(children) : []
  }

  private buildNavItems(routes: Routes): NavLink[] {
    return (routes)
      .filter(route => route.data)
      .map(({ path = '', data }) => ({
        path: path,
        label: data?.['label'] ?? '',
        icon: data?.['icon'],
        beta: false
      }))
  }

}
