<h2 mat-dialog-title>{{ 'editDialog.title' | translate }}</h2>

<div mat-dialog-content>
  <form [formGroup]="form">

    @for (key of keys; track key) {

      @if (isFormArray(key)) {
        <label>{{ getTranslation(key) }}</label>

        @for (control of getFormArray(key).controls; track $index) {
          <div style="display: flex; align-items: center; gap: 10px;">
            <mat-form-field appearance="outline" style="flex: 1;">
              <mat-label>{{ getTranslation(key) }} {{ $index + 1 }}</mat-label>
              <input matInput [formControl]="getFormControl(control)">
            </mat-form-field>

            <!-- Remove Button -->
            <button mat-icon-button color="warn" (click)="removeArrayItem(key, $index)">
              <mat-icon>remove_circle</mat-icon>
            </button>
          </div>
        }

        <button mat-button color="primary" (click)="addArrayItem(key)">
          <mat-icon>add_circle</mat-icon> Add {{ getTranslation(key) }}
        </button>
      }
      @else {
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>{{ getTranslation(key) }}</mat-label>
          <input matInput [formControlName]="key">
        </mat-form-field>
      }

    }
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">{{ 'action.cancel' | translate }}</button>
  <button mat-raised-button color="primary" (click)="onSave()" [disabled]="form.invalid">
    {{ 'action.save' | translate }}
  </button>
</div>
