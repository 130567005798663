{
  "name": "@mybitzer-workspace/source",
  "version": "4.9.4",
  "license": "MIT",
  "scripts": {
    "serve:bo": "npx nx serve mybitzer-backoffice",
    "serve:fe": "npx nx serve mybitzer-frontend",
    "analyze:bo": "npx nx build mybitzer-backoffice --stats-json --named-chunks && npx @angular-experts/hawkeye dist/apps/mybitzer-backoffice/stats.json",
    "analyze:fe": "npx nx build mybitzer-frontend --stats-json --named-chunks && npx @angular-experts/hawkeye dist/apps/mybitzer-frontend/stats.json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.2.4",
    "@angular/cdk": "19.2.4",
    "@angular/common": "19.2.4",
    "@angular/compiler": "19.2.4",
    "@angular/core": "19.2.4",
    "@angular/forms": "19.2.4",
    "@angular/material": "19.2.4",
    "@angular/material-luxon-adapter": "19.2.4",
    "@angular/platform-browser": "19.2.4",
    "@angular/platform-browser-dynamic": "19.2.4",
    "@angular/router": "19.2.4",
    "@bluehalo/ngx-leaflet": "^19.0.0",
    "@frxjs/ngx-timeline": "^19.0.2",
    "@hakimio/ngx-google-analytics": "^15.0.0",
    "@ngneat/overview": "^6.0.0",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^16.0.1",
    "@ngxpert/hot-toast": "^3.0.2",
    "@svgdotjs/svg.js": "^3.2.4",
    "@tailwindcss/typography": "0.5.16",
    "@types/leaflet.markercluster": "^1.5.5",
    "@usercentrics/cmp-browser-sdk": "^4.41.0",
    "country-region-data": "^3.1.0",
    "d3": "^7.9.0",
    "echarts": "^5.6.0",
    "file-saver": "^2.0.5",
    "keycloak-angular": "^19.0.2",
    "keycloak-js": "^26.2.0",
    "leaflet": "^1.9.4",
    "leaflet.markercluster": "^1.5.3",
    "logrocket": "^9.0.2",
    "luxon": "^3.5.0",
    "ngx-cookie-service": "^19.1.2",
    "ngx-echarts": "^19.0.0",
    "rxjs": "~7.8.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.2.4",
    "@angular-devkit/core": "19.2.4",
    "@angular-devkit/schematics": "19.2.4",
    "@angular/cli": "~19.2.4",
    "@angular/compiler-cli": "19.2.4",
    "@angular/language-service": "19.2.4",
    "@eslint/js": "^9.8.0",
    "@nx/angular": "20.6.4",
    "@nx/eslint": "20.6.4",
    "@nx/eslint-plugin": "20.6.4",
    "@nx/jest": "20.6.4",
    "@nx/js": "20.6.4",
    "@nx/web": "20.6.4",
    "@nx/workspace": "20.6.4",
    "@schematics/angular": "19.2.4",
    "@softarc/detective": "^1.3.0",
    "@swc-node/register": "~1.9.1",
    "@swc/core": "~1.5.7",
    "@swc/helpers": "~0.5.11",
    "@types/file-saver": "^2.0.7",
    "@types/jest": "^29.5.12",
    "@types/leaflet": "^1.9.16",
    "@types/luxon": "^3.4.2",
    "@types/node": "18.16.9",
    "@typescript-eslint/utils": "^8.13.0",
    "angular-eslint": "19.2.0",
    "autoprefixer": "^10.4.0",
    "eslint": "^9.8.0",
    "eslint-config-prettier": "^9.0.0",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-preset-angular": "~14.4.0",
    "nx": "20.6.4",
    "postcss": "^8.4.5",
    "prettier": "^2.6.2",
    "tailwindcss": "^3.4.17",
    "ts-jest": "^29.1.0",
    "ts-node": "10.9.1",
    "tslib": "^2.3.0",
    "typescript": "5.7.3",
    "typescript-eslint": "^8.13.0"
  }
}
