<mat-card-actions class="flex flex-row gap-2">
    @if (!editMode()) {
    <button mat-stroked-button (click)="toggleEdit()">
        <mat-icon>edit</mat-icon>
        {{ 'action.edit' | translate }}
    </button>
    }

    @if (editMode()) {
    <button mat-stroked-button (click)="save()">
        <mat-icon>save</mat-icon>
        {{ 'action.save' | translate }}
    </button>
    <button mat-stroked-button (click)="toggleEdit()">
        {{ 'action.cancel' | translate }}
    </button>
    }
</mat-card-actions>