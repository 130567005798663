import { DatePipe } from '@angular/common'
import { AfterViewInit, Component, OnInit, input, viewChild } from '@angular/core'
import { MatButton } from '@angular/material/button'
import { MatPaginator } from "@angular/material/paginator"
import { MatProgressBar } from '@angular/material/progress-bar'
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatNoDataRow, MatRow, MatRowDef, MatTable, MatTableDataSource } from "@angular/material/table"
import { Transaction } from '../account/account.api'
import { TransactionListDataProvider } from "./transaction-list-data-provider"
import { TranslatePipe } from '@ngx-translate/core'
import { Page } from '../../shared.api'

@Component({
  selector: 'lib-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
  imports: [MatProgressBar, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatButton, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow, MatPaginator, DatePipe, TranslatePipe]
})
export class TransactionListComponent implements OnInit, AfterViewInit {


  displayedColumns: string[] = ['timestamp', 'reference', 'amount', 'orderNr', 'type']
  dataSource: MatTableDataSource<Transaction> = new MatTableDataSource()
  reloading = false
  pageSize = 20
  resultsLength = 0
  readonly paginator = viewChild(MatPaginator)
  readonly provider = input<TransactionListDataProvider>()

  ngOnInit(): void {
    this.loadPage(0)
  }

  ngAfterViewInit() {
    const paginator = this.paginator()
    if (!paginator) return
    paginator.page.subscribe(p => this.loadPage(p.pageIndex))
  }

  private loadPage(pageNr: number) {
    const provider = this.provider()
    if (!provider) return
    this.reloading = true
    provider.getNextPage(pageNr, this.pageSize).subscribe(d => this.setData(d))
  }

  private setData(page: Page<Transaction>) {
    this.dataSource.data = page.content
    this.resultsLength = page.totalSize
    this.reloading = false
  }


  reload() {
    const paginator = this.paginator()
    if (paginator) {
      paginator.pageIndex = 0
    }
    this.loadPage(0)
  }
}
