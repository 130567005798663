@if (reloading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
<div class="flex flex-col px-2 gap-2 pt-2">
  <table mat-table [dataSource]="dataSource" class="grow shrink basis-auto">

    <ng-container matColumnDef="chargeDate">
      <th mat-header-cell *matHeaderCellDef>{{'timestamp' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.chargeDate | date:  'short' }} </td>
    </ng-container>

    <ng-container matColumnDef="validityPeriodStart">
      <th mat-header-cell *matHeaderCellDef>{{'charge_entry.Table.ValidFrom' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.validityPeriodStart | date:  'short' }} </td>
    </ng-container>

    <ng-container matColumnDef="validityPeriodEnd">
      <th mat-header-cell *matHeaderCellDef>{{'charge_entry.Table.ValidTo' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.validityPeriodEnd | date:  'short' }} </td>
    </ng-container>

    <ng-container matColumnDef="charge">
      <th mat-header-cell *matHeaderCellDef>{{'charge' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.charge}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{'type' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.type}} </td>
    </ng-container>

    <ng-container matColumnDef="policy">
      <th mat-header-cell *matHeaderCellDef>{{'charge_entry.Table.Policy' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.policyId}}</td>
    </ng-container>

    <ng-container matColumnDef="license">
      <th mat-header-cell *matHeaderCellDef>{{'license.title' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.licenseId}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="background-primary"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="7">{{'charge_entry.Table.NoData' | translate}}</td>
    </tr>
  </table>
  <mat-paginator [length]="resultsLength" [pageSize]="pageSize"></mat-paginator>
</div>
