@if (form()) {
  <form class="flex flex-col mt-3" [formGroup]="form()">
    <mat-form-field appearance="outline">
      <mat-label>{{'address.street' | translate}}</mat-label>
      <input type="text" matInput formControlName="street" [readonly]="readonly()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'address.postalCode' | translate}}</mat-label>
      <input type="text" matInput formControlName="zip" [readonly]="readonly()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'address.city' | translate}}</mat-label>
      <input type="text" matInput formControlName="city" [readonly]="readonly()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'address.country' | translate}}</mat-label>
      <input type="text" matInput formControlName="country" [readonly]="readonly()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'address.region' | translate}}</mat-label>
      <input type="text" matInput formControlName="state" [readonly]="readonly()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'address.phone' | translate}}</mat-label>
      <input type="text" matInput formControlName="phoneNumber" [readonly]="readonly()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'address.web' | translate}}</mat-label>
      <input type="text" matInput formControlName="website" [readonly]="readonly()">
    </mat-form-field>
  </form>
}
