<mat-toolbar color="primary" class="gap-2  items-center">
  <button mat-icon-button (click)="back()">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <div style="flex: 0 1 45px;">

  </div>
  @if (service.company) {
    <div class="flex flex-col ps-2 gap-1" style="line-height: normal !important;">
      <div>
        <h5><strong>{{service.company.name}}</strong></h5>
      </div>
      <div class="flex flex-row gap-2">
        <div class="details"></div>
        <div class="details"></div>
      </div>
    </div>
  }


  <div class="ps-5 flex-auto">

  </div>


  <div>
    <button mat-flat-button (click)="showInvitationDialog()">
      <mat-icon>person_add</mat-icon>
      {{'inviteNewUser' | translate}}
    </button>
  </div>


</mat-toolbar>
