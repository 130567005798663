import { Component, input } from '@angular/core'
import { MatProgressBar } from '@angular/material/progress-bar'

@Component({
  selector: 'lib-loading-bar',
  imports: [MatProgressBar],
  templateUrl: './loading-bar.component.html',
  styleUrl: './loading-bar.component.scss',
})
export class LoadingBarComponent {
  loading = input.required<boolean>()
}
