import { BreakpointObserver } from '@angular/cdk/layout'
import { inject, Injectable, signal } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { TranslateService } from "@ngx-translate/core"
import { HotToastService } from "@ngxpert/hot-toast"
import { map, Observable, shareReplay } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private readonly translate = inject(TranslateService)
  lang = signal<string>(this.translate.currentLang)

  constructor(private readonly toast: HotToastService, private readonly breakpointObserver: BreakpointObserver) {
    this.translate.onLangChange.pipe(takeUntilDestroyed()).subscribe(e => this.lang.set(e.lang))
  }

  handleError(err: any) {
    if (!err) return
    this.toast.error("Error while loading data", err)
  }

  isMobile$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 1000px)'])
    .pipe(
      map(result => result.matches),
      shareReplay()
    )

}
