import { Component, input } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from "@angular/forms"

import { MatFormField, MatLabel } from '@angular/material/form-field'
import { MatInput } from '@angular/material/input'
import { TranslatePipe } from '@ngx-translate/core'

@Component({
  selector: 'lib-company-contact-form',
  templateUrl: './company-contact-form.component.html',
  styleUrls: ['./company-contact-form.component.scss'],
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatInput, TranslatePipe]
})
export class CompanyContactFormComponent {

  readonly readonly = input<boolean>(false)
  readonly form = input.required<FormGroup>()

}
