import { AfterContentInit, AfterViewInit, Component, contentChildren, input, OnInit, viewChild } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatIconButton } from '@angular/material/button'
import { MatFormField, MatLabel } from '@angular/material/form-field'
import { MatIcon } from '@angular/material/icon'
import { MatInput } from '@angular/material/input'
import { MatPaginator } from "@angular/material/paginator"
import { MatColumnDef, MatHeaderRow, MatRow, MatTable, MatTableDataSource, MatTableModule } from "@angular/material/table"
import { MatToolbar } from '@angular/material/toolbar'
import { EMPTY, Observable, Subject, switchMap, tap } from 'rxjs'
import { debounceTime } from "rxjs/operators"
import { BoardListDataProvider } from "./board-list-data-provider"

import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { MatCard } from '@angular/material/card'
import { MatProgressBar } from '@angular/material/progress-bar'
import { BoardComponent } from "../board/board.component"
import { Page } from '../shared.api'

@Component({
  selector: 'lib-board-list',
  templateUrl: './board-list.component.html',
  styleUrls: ['./board-list.component.scss'],
  imports: [MatToolbar, MatFormField, MatLabel, MatInput, ReactiveFormsModule, FormsModule, MatIconButton, MatIcon, MatProgressBar, BoardComponent, MatCard, MatTableModule, MatHeaderRow, MatRow, MatPaginator]
})
export class BoardListComponent<T> implements OnInit, AfterViewInit, AfterContentInit {

  readonly provider = input<BoardListDataProvider<T> | null>(null)
  dataSource: MatTableDataSource<T> = new MatTableDataSource()
  readonly displayedColumns = input<string[]>([])
  reloading = false
  pageSize = 20
  resultsLength = 0
  readonly paginator = viewChild(MatPaginator)
  filter = ''
  readonly table = viewChild(MatTable)
  readonly columnDefs = contentChildren(MatColumnDef)

  private subject: Subject<string> = new Subject()

  constructor() {

    this.subject.pipe(
      debounceTime(1000),
      switchMap((searchTextValue) => {
        this.filter = searchTextValue
        return this.loadPage(0)
      }),
      takeUntilDestroyed()
    ).subscribe()
  }

  ngAfterContentInit() {
    const columnDefs = this.columnDefs()
    if (!columnDefs) return
    columnDefs.forEach(columnDef => this.table()?.addColumnDef(columnDef))
  }

  ngOnInit(): void {
    this.loadPage(0).subscribe()
  }

  ngAfterViewInit() {
    const paginator = this.paginator()
    if (!paginator) return
    paginator.page.pipe(switchMap(p => this.loadPage(p.pageIndex))).subscribe()
  }


  private handleData(p: Page<T>) {
    this.dataSource.data = p?.content
    this.resultsLength = p?.totalSize
    this.reloading = false
  }

  onKeyUp(searchTextValue: string) {
    this.subject.next(searchTextValue)
  }

  private loadPage(page: number) {
    const provider = this.provider()
    let response: Observable<Page<T>> = EMPTY
    if (!provider || this.reloading) return EMPTY
    this.reloading = true
    if (this.filter && this.filter.length > 0) {
      response = provider.loadFilteredPage(this.filter, page, this.pageSize)
    } else {
      response = provider.loadPage(page, this.pageSize)
    }

    return response.pipe(tap((p) => this.handleData(p))
    )
  }

  clearFilter() {
    this.filter = ''
    this.loadPage(0).subscribe()
  }
}
