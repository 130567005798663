import { Injectable } from '@angular/core'
import { HttpParams, HttpResponse } from "@angular/common/http"
import { Observable } from "rxjs"
import { FileUploadLogSearchResponse } from "./file-upload.api"
import { BaseService } from '../base.service'

@Injectable({
  providedIn: 'root'
})
export abstract class FileUploadService extends BaseService {
  protected constructor(urlPrefix: string) {
    super(urlPrefix)
    this.retryCount = 0
  }

  upload(file: FormData) {
    return this.post('upload', file)
  }

  search(page: number, size: number): Observable<HttpResponse<FileUploadLogSearchResponse>> {
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.postResponse<FileUploadLogSearchResponse>('search', {}, params)
  }
}
