<h2 mat-dialog-title>{{ data.titleKey | translate }}</h2>
<mat-dialog-content>{{ data.messageKey | translate: data.messageParams }}</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">{{'action.cancel' | translate }}</button>
  @if(data.isDelete){
    <button mat-button (click)="onConfirm()" color="warn">{{'action.delete' | translate }}</button>
  } @else {
    <button mat-button (click)="onConfirm()" color="primary">{{'action.confirm' | translate }}</button>
  }
</mat-dialog-actions>
