import { InjectionToken } from "@angular/core"
import { Observable } from "rxjs"

export interface Menu {
  id: number,
  name: string,
  priority: number,
  groups: MenuActionGroup[]
}

export interface MenuActionGroup {
  id: number,
  name: string,
  rank: number,
  actions: MenuAction[]
}

export interface MenuAction {
  id: number,
  name: string,
  icon: string,
  url: string,
  rank: number,
  roles: string[],
  status: MenuActionStatus | undefined
}

export interface MenuActionStatus {
  text: string,
  color: string
}
export interface IMenuService {
  getMenu(): Observable<Menu>
}

export const MENU_SERVICE = new InjectionToken<IMenuService>('MENU_SERVICE')