import { HttpEvent, HttpEventType } from "@angular/common/http"
import { Component, input, output } from '@angular/core'
import { MatFabButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'
import { HotToastService } from "@ngxpert/hot-toast"
import { Subscription } from "rxjs"
import { finalize } from "rxjs/operators"
import { Image } from "../image-api"
import { ImageService } from "../image.service"

import { MatProgressBar } from '@angular/material/progress-bar'
import { TranslatePipe } from '@ngx-translate/core'

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  imports: [MatFabButton, MatIcon, MatProgressBar, TranslatePipe]
})
export class ImageUploadComponent {

  readonly itemId = input<number>();
  readonly event = output<boolean>();
  fileName = ''

  uploadProgress: number | undefined
  uploadSub: Subscription | undefined
  uploading: boolean = false

  constructor(private service: ImageService, private toast: HotToastService) {
  }

  onFileSelected(event: any) {
    const itemId = this.itemId()
    if (!itemId) return

    const file: File = event.target.files[0]
    if (!file) return

    this.fileName = file.name
    this.uploading = true

    const formData = new FormData()
    formData.append("file", file, this.fileName)

    const upload$ = this.service.upload(itemId, formData).pipe(
      finalize(() => this.reset())
    )

    this.uploadSub = upload$.subscribe(
      {
        next: (evt) => this.handleUpdate(evt),
        error: (err) => this.handleFailed(err),
      })
  }

  private handleUpdate(event: HttpEvent<Image>) {
    if (event.type == HttpEventType.UploadProgress) {
      this.uploadProgress = Math.round(100 * (event.loaded / event.total!!))
    } else if (event.type == HttpEventType.Response) {
      this.handleCompleted(event.body!!)
    }
  }

  private handleCompleted(v: Image) {
    this.toast.success("Successfully uploaded file '" + this.fileName + "' ")
    this.event.emit(true)
  }

  private handleFailed(e: any) {
    this.toast.error("Failed to upload file '" + this.fileName + "' ")
    this.event.emit(false)
  }

  reset() {
    this.uploadProgress = undefined
    this.uploadSub = undefined
    this.uploading = false
  }
}
