
import { AfterViewInit, Component, Input, OnInit, viewChild } from '@angular/core'
import { MatButton } from '@angular/material/button'
import { MatDialog } from '@angular/material/dialog'
import { MatIcon } from '@angular/material/icon'
import { MatPaginator } from '@angular/material/paginator'
import { MatProgressBar } from '@angular/material/progress-bar'
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatNoDataRow, MatRow, MatRowDef, MatTable, MatTableDataSource } from '@angular/material/table'
import { TranslatePipe } from '@ngx-translate/core'
import { EMPTY, filter, map, Observable, switchMap, tap } from 'rxjs'
import { Page } from '../../../shared.api'
import { VoucherListDataProvider } from '../provider'
import { ReleaseVoucherByLinkDialogComponent } from '../release-voucher-by-link-dialog/release-voucher-by-link-dialog.component'
import { ReleaseVoucherByMailDialogComponent } from '../release-voucher-by-mail-dialog/release-voucher-by-mail-dialog.component'
import { Voucher } from '../voucher.api'
import { VoucherService } from '../voucher.service'

@Component({
  selector: 'lib-voucher-list',
  templateUrl: './voucher-list.component.html',
  styleUrls: ['./voucher-list.component.scss'],
  imports: [MatProgressBar, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatButton, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow, MatPaginator, TranslatePipe]
})
export class VoucherListComponent implements OnInit, AfterViewInit {


  displayedColumns: string[] = ['reference', 'amount', 'status', 'action']
  dataSource: MatTableDataSource<Voucher> = new MatTableDataSource()
  reloading = false
  pageSize = 20
  resultsLength = 0
  readonly paginator = viewChild(MatPaginator)
  @Input()
  provider: VoucherListDataProvider | undefined

  constructor(private readonly service: VoucherService, private readonly dialog: MatDialog) {
    if (!this.provider) {
      this.provider = {
        getNextPage(pageNr: number, pageSize: number): Observable<Page<Voucher>> {
          return service.getAllVouchers(pageNr, pageSize)
        }
      }
    }
  }

  ngOnInit(): void {
    this.loadPage(0).subscribe()
  }

  ngAfterViewInit() {
    const paginator = this.paginator()
    if (!paginator) return
    paginator.page.pipe(switchMap(p => this.loadPage(p.pageIndex))).subscribe()
  }

  private loadPage(pageNr: number): Observable<void> {
    if (!this.provider) return EMPTY
    this.reloading = true
    return this.provider.getNextPage(pageNr, this.pageSize).pipe(
      tap(d => this.setData(d)),
      map(() => void 0)
    )
  }

  private setData(page: Page<Voucher>) {
    this.dataSource.data = page.content
    this.resultsLength = page.totalSize
    this.reloading = false
  }

  reload() {
    const paginator = this.paginator()
    if (paginator) {
      paginator.pageIndex = 0
    }
    return this.loadPage(0)
  }

  showReleaseVoucherByLinkDialog(voucher: Voucher) {
    this.dialog.open(ReleaseVoucherByLinkDialogComponent, {
      data: voucher
    }).afterClosed().pipe(
      filter(result => !!result),
      switchMap(() => this.loadPage(0))
    ).subscribe()
  }

  showReleaseVoucherByMailDialog(voucher: Voucher) {
    this.dialog.open(ReleaseVoucherByMailDialogComponent, {
      data: voucher
    }).afterClosed().pipe(
      filter(result => !!result),
      switchMap(() => this.loadPage(0))
    ).subscribe()
  }
}
