import { Injectable } from '@angular/core'
import {MatDialog} from "@angular/material/dialog"
import {Observable} from "rxjs"
import {DialogComponent} from "./dialog.component"
import { ConfirmDialogComponent } from './confirm-dialog.component'

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private readonly dialog: MatDialog) {}

  openDialog<T>(data: T, title: string): Observable<T | undefined> {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: { object: data, title }
    })

    return dialogRef.afterClosed()
  }

  confirm(titleKey: string, messageKey: string, isDelete = false, messageParams?: any): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { titleKey, messageKey, isDelete, messageParams }
    })

    return dialogRef.afterClosed()
  }


}
