import { Injectable } from '@angular/core'

import { Observable } from "rxjs"

import { BaseService } from '../../base.service'
import { Page } from '../../shared.api'
import { AccountInfo } from '../account'
import { LicenseModel, LicensePolicy } from '../license/license.api'
import { PermissionSchema, PermissionSchemaChangeRequest } from "./permission.api"


@Injectable({
  providedIn: 'root'
})
export class PermissionService extends BaseService {

  constructor() {
    super('accounting/permissions')
  }

  getAllPermissionSchemas(page: number, size = 20): Observable<Page<PermissionSchema>> {
    return this.getPaged<PermissionSchema>('', page, size)
  }


  getPermissionSchema(id: number): Observable<PermissionSchema> {
    return this.get(id + '')
  }

  getAssignedAccountInfos(id: number, page: number, size = 20): Observable<Page<AccountInfo>> {
    return this.getPaged(id + '/account/info', page, size)
  }

  getAssignedLicenseModels(id: number, page: number, size = 20): Observable<Page<LicenseModel>> {
    return this.getPaged(id + '/model', page, size)
  }

  getAssignedLicensePolicies(id: number, page: number, size = 20): Observable<Page<LicensePolicy>> {
    return this.getPaged(id + '/policy', page, size)
  }

  createPermissionSchema(request: PermissionSchemaChangeRequest): Observable<PermissionSchema> {
    return this.post('', request)
  }

  updatePermissionSchema(id: number, request: PermissionSchemaChangeRequest): Observable<PermissionSchema> {
    return this.put('' + id, request)
  }

  deletePermissionSchema(id: number): Observable<any> {
    return this.delete('' + id)
  }
}
