import { AfterViewInit, Component, OnInit, input, viewChild } from '@angular/core'
import { MatPaginator } from "@angular/material/paginator"
import {
  MatTableDataSource,
  MatTableModule
} from "@angular/material/table"

import { DatePipe } from '@angular/common'
import { MatProgressBar } from '@angular/material/progress-bar'
import { TranslatePipe } from '@ngx-translate/core'
import { EMPTY, switchMap, tap } from 'rxjs'
import { ChargeListDataProvider } from '../../voucher/provider/charge-list-data-provider'
import { ChargeEntry } from '../charge.api'
import { Page } from '../../../shared.api'

@Component({
  selector: 'lib-charge-list',
  templateUrl: './charge-list.component.html',
  styleUrls: ['./charge-list.component.scss'],
  imports: [MatProgressBar, MatTableModule, DatePipe, TranslatePipe, MatPaginator]
})
export class ChargeListComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['chargeDate', 'validityPeriodStart', 'validityPeriodEnd', 'charge', 'type', 'policy', 'license']
  dataSource: MatTableDataSource<ChargeEntry>
  reloading = false
  pageSize = 20
  resultsLength = 0
  readonly paginator = viewChild(MatPaginator)
  readonly provider = input<ChargeListDataProvider>()

  constructor() {
    this.dataSource = new MatTableDataSource()
  }

  ngOnInit(): void {
    this.loadPage(0).subscribe()
  }
  ngAfterViewInit() {
    const paginator = this.paginator()
    if (!paginator) return
    paginator.page.pipe(switchMap(p => this.loadPage(p.pageIndex))).subscribe()
  }

  private loadPage(pageNr: number) {
    const provider = this.provider()
    if (!provider) return EMPTY
    this.reloading = true
    return provider.getNextPage(pageNr, this.pageSize).pipe(tap((d: any) => this.setData(d)))
  }

  private setData(d: Page<ChargeEntry>) {
    this.resultsLength = d.totalSize
    this.dataSource.data = d.content
    this.reloading = false
  }

}
