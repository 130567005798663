import { Injectable } from '@angular/core'

import { HttpParams } from "@angular/common/http"
import { Observable } from "rxjs"
import { BaseService } from '../base.service'
import { Page, PatchRequest } from '../shared.api'
import { Customer, CustomerInfo, CustomerSearchRequest, Subscription } from "./customer.api"


@Injectable({
  providedIn: 'root'
})
export class CustomerService extends BaseService {

  constructor() {

    super('customer')
    this.retryCount = 1
  }

  searchCustomer(request: CustomerSearchRequest, page: number, size: number): Observable<Page<CustomerInfo>> {
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.post('search', request, params)
  }

  getById(id: string): Observable<CustomerInfo> {
    return this.get(id)
  }

  setName(id: number, value: string): Observable<Customer> {
    return this.patch(id + '/name', new PatchRequest(value))
  }

  getByEmail(email: string): Observable<Customer> {
    return this.get('mail?email=' + email)
  }

  findByEmail(email: string[]): Observable<Customer[]> {
    return this.get('find/by/email?email=' + email)
  }

  remove(id: number): Observable<Customer> {
    return this.delete(id + '')
  }

  setSubscriptionTypes(customerId: number, types: string[]): Observable<Subscription> {
    return this.put(customerId + '/subscription', new PatchRequest(types))
  }
}
