import { HttpParams } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { Account, AccountChangeRequest, AccountInfo, Transaction, TransactionChangeRequest } from "./account.api"


import { Page } from '@mybitzer-workspace/shared'
import { Observable, map } from 'rxjs'
import { BaseService } from '../../base.service'
import { ChargeEntry } from '../charge/charge.api'
import { Company } from "../company/company.api"
import { License, LicenseInfo } from '../license/license.api'

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {

  constructor() {
    super('accounting/account')
  }

  getAllAccounts(page: number, size = 20): Observable<Page<Account>> {
    return this.getPaged<Account>('', page, size)
  }

  private allInfoCache = new Map<string, Observable<Page<AccountInfo>>>()

  getAllAccountInfos(page: number, size: number): Observable<Page<AccountInfo>> {
    const cacheKey = page + '' + size
    const hit = this.allInfoCache.get(cacheKey)
    if (hit) return hit

    const result = this.getPaged<AccountInfo>('info', page, size)
    this.allInfoCache.set(cacheKey, result)
    return result
  }

  getFilteredAccountInfos(page: number, size: number, filter: string): Observable<Page<AccountInfo>> {
    let params = new HttpParams()
    params = params.append('filter', filter)
    return this.getPaged<AccountInfo>('info/filter', page, size, params)
  }

  getAccount(accountId: number): Observable<Account> {
    return this.get('' + accountId)
  }

  private infoCache = new Map<number, Observable<AccountInfo>>()

  getAccountInfo(accountId: number): Observable<AccountInfo> {
    const hit = this.infoCache.get(accountId)
    if (hit) return hit

    const result = this.get<AccountInfo>('' + accountId + '/info')
    this.infoCache.set(accountId, result)
    return result
  }


  private readonly byCompanyCache = new Map<number, Observable<AccountInfo>>()

  findInfoByCompany(company: Company): Observable<AccountInfo> {
    const hit = this.byCompanyCache.get(company.id)
    if (hit) return hit

    const result = this.get<AccountInfo>('find/by/company/' + company.id + '/info')
    this.byCompanyCache.set(company.id, result)
    return result
  }


  findByCompany(companyId: number): Observable<Account> {
    return this.get('find/by/company/' + companyId)
  }

  createAccount(request: AccountChangeRequest): Observable<Account> {
    const observable = this.post<Account>('', request)
    return observable.pipe(
      map(a => {
        this.infoCache.delete(a.id)
        this.allInfoCache.clear()
        return a
      })
    )
  }

  getTransactions(accountId: number, page: number, size = 20): Observable<Page<Transaction>> {
    return this.getPaged<Transaction>(accountId + '/transaction', page, size)
  }

  getChargeEntries(accountId: number, page: number, size = 20): Observable<Page<ChargeEntry>> {
    return this.getPaged<ChargeEntry>(accountId + '/charge', page, size)
  }

  getLicenses(accountId: number, page: number, size = 20): Observable<Page<License>> {
    return this.getPaged<License>(accountId + '/license', page, size)
  }

  getLicenseInfos(accountId: number, page: number, size: number): Observable<Page<LicenseInfo>> {
    return this.getPaged<LicenseInfo>(accountId + '/license/info', page, size)
  }

  getFilteredLicenseInfos(accountId: number, page: number, size: number, filter: string) {
    let params = new HttpParams()
    params = params.append('filter', filter)
    return this.getPaged<LicenseInfo>(accountId + '/license/info/filter', page, size, params)
  }

  getUserAccountInfo(): Observable<AccountInfo> {
    return this.get('user/info')
  }

  accountOrder(accountId: number, request: TransactionChangeRequest): Observable<Transaction> {
    return this.post(accountId + '/transaction', request)
  }

  recalculate(accountId: number): Observable<Transaction> {
    return this.post(accountId + '/recalculate', {})
  }
}
