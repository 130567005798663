import { inject, Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from "@ngx-translate/core"
import { DateTime } from "luxon"

@Pipe({
  name: 'relativeDate',
  standalone: true
})
export class RelativeDatePipe implements PipeTransform {
  private readonly translate = inject(TranslateService)

  transform(value: string | Date): string {
    if (value) {
      const date = DateTime.fromISO(value.toString())
      const now = DateTime.now()

      if (now.diff(date, 'seconds').seconds < 30) {
        return this.translate.instant('relativeDate.justNow')
      }

      const relativeTime = date.toRelative() ?? ''
      const formattedDate = date.toFormat("h:mm")
      return this.translate.instant('relativeDate.timeAt', { relativeTime, formattedDate }) ?? ''
    }
    return ''
  }

}
