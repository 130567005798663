import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { CdkScrollable } from '@angular/cdk/scrolling';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import { MatButton } from '@angular/material/button';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-image-upload-dialog',
    templateUrl: './image-upload-dialog.component.html',
    styleUrls: ['./image-upload-dialog.component.scss'],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, ImageUploadComponent, MatDialogActions, MatButton, MatDialogClose, TranslatePipe]
})
export class ImageUploadDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public itemId: number,
    public dialogRef: MatDialogRef<ImageUploadDialogComponent>
  ) {
  }

  handleUploadFinished() {
    this.dialogRef.close()
  }
}
