<h1 mat-dialog-title>{{"voucher.Dialog.ReleaseMail.Title" | translate}}:
  {{this.data.reference}}
</h1>
<div mat-dialog-content>
  @if (reloading) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
  <div class="flex flex-col justify-start mw-100 gap-2">
    <mat-form-field class="grow shrink basis-auto">
      <mat-label>{{'voucher.Dialog.ReleaseMail.Label' | translate}}</mat-label>
      <input matInput placeholder="Search" aria-label="State" [matAutocomplete]="auto" [formControl]="searchCustomerCtrl">

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="handleUserSelect($event)">
        @if (reloading) {
          <mat-option class="is-loading">Loading...</mat-option>
        }
        @if (!reloading) {
          @for (c of searchResult; track c) {
            <mat-option [value]="c">
              <span>{{c.name}} {{c.email}}</span>
            </mat-option>
          }
        }
      </mat-autocomplete>
    </mat-form-field>

    <mat-list>
      @for (c of selectedCustomer; track c) {
        <mat-list-item>
          <mat-icon mat-list-icon>contact_mail</mat-icon>
          <div mat-line>{{c.name}}</div>
          <div mat-line> {{c.email}} </div>
        </mat-list-item>
      }
    </mat-list>

  </div>
</div>
<div mat-dialog-actions>
  <div class="flex flex-row justify-center mh-100 gap-2">
    <button mat-button mat-stroked-button color="primary" (click)="onReleaseClick()" class="grow shrink basis-auto">
      <mat-icon>move_to_inbox</mat-icon>
    {{"voucher.Dialog.Action.Release" | translate}}</button>
    <button mat-button mat-stroked-button color="warn" (click)="onCancelClick()" class="grow shrink basis-auto">
      <mat-icon>cancel</mat-icon>
    {{"action.Cancel" | translate}}</button>
  </div>
</div>
