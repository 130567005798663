import { Component, Input } from '@angular/core'
import { FormBuilder } from "@angular/forms"
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card'

import { TranslatePipe } from '@ngx-translate/core'
import { Company, CompanyContact, CompanyContactChangeRequest, CompanyService } from '../../accounting'
import { EditSaveActionsComponent } from '../../edit-save-actions/edit-save-actions.component'
import { LoadingBarComponent } from '../../loading-bar/loading-bar.component'
import { CompanyContactFormComponent } from '../company-contact-form/company-contact-form.component'

@Component({
  selector: 'lib-company-details-contact',
  templateUrl: './company-details-contact.component.html',
  styleUrls: ['./company-details-contact.component.scss'],
  imports: [MatCard, MatCardHeader, MatCardTitle, MatCardContent, CompanyContactFormComponent, TranslatePipe, LoadingBarComponent, EditSaveActionsComponent]
})
export class CompanyDetailsContactComponent {

  @Input()
  set data(data: Company | undefined) {
    this.company = data
    if (this.company) {
      this.updateData()
    }
  }

  company: Company | undefined
  contact: CompanyContact | undefined
  reloading = false
  editMode = false

  form = this.service.createContactForm(this.fb)

  constructor(private service: CompanyService, private fb: FormBuilder) {
  }

  private updateData() {
    if (!this.company) return
    if (this.reloading) return
    this.reloading = true
    this.service.getContact(this.company.id).subscribe(
      {
        next: value => this.handleData(value),
        complete: () => this.reloading = false
      }
    )
  }

  private handleData(data: CompanyContact) {
    this.contact = data
    this.form.setValue(data)
    this.reloading = false
  }

  save() {
    if (!this.company) return
    if (!this.form.valid) return
    if (this.reloading) return
    this.reloading = true

    const value = this.form.value
    const request = new CompanyContactChangeRequest(
      value.title ?? "",
      value.firstName ?? "",
      value.lastName ?? "",
      value.phoneNumber ?? "",
      value.email ?? "",
    )
    this.service.updateContact(this.company.id, request).subscribe(
      {
        next: value => this.handleData(value),
        complete: () => this.reloading = false
      }
    )
  }


}
