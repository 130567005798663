import { Component } from '@angular/core'
import { HotToastService } from "@ngxpert/hot-toast"
import { Subscription } from "rxjs"
import { finalize } from "rxjs/operators"

import { MatFabButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'
import { MatProgressBar } from '@angular/material/progress-bar'
import { TranslatePipe } from '@ngx-translate/core'
import { FileUploadService } from "../file-upload/file-upload.service"

@Component({
  selector: 'lib-file-upload-import',
  templateUrl: './file-upload-import.component.html',
  styleUrl: './file-upload-import.component.scss',
  imports: [MatProgressBar, MatFabButton, MatIcon, TranslatePipe]
})
export class FileUploadImportComponent {
  fileName = ''
  isDragging = false

  uploadProgress: number | undefined
  uploadSub: Subscription | undefined
  uploading = false

  constructor(private service: FileUploadService, private toast: HotToastService) {
  }

  onDragOver(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.isDragging = true
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault()
    this.isDragging = false
  }

  onDrop(event: DragEvent) {
    event.preventDefault()
    this.isDragging = false

    if (event.dataTransfer?.files.length) {
      const file = event.dataTransfer.files[0]
      if (file) this.selectFile(file)
    }
  }


  onFileSelected(event: any) {
    const file: File = event.target.files[0]
    if (!file) return
    this.selectFile(file)
  }

  selectFile(file: File) {
    this.fileName = file.name
    this.uploading = true

    const formData = new FormData()
    formData.append("file", file, this.fileName)


    const upload$ = this.service.upload(formData).pipe(
      finalize(() => this.reset())
    )

    this.uploadSub = upload$.subscribe(
      {

        next: (evt) => this.handleCompleted(evt),
        error: (err) => this.handleFailed(err),
      })
  }

  private handleCompleted(v: any) {
    this.toast.success("Successfully uploaded file '" + this.fileName + "' ")
    this.uploading = false
  }

  private handleFailed(e: any) {
    this.toast.error("Failed to upload file '" + this.fileName + "' ")
    this.uploading = false
  }

  reset() {
    this.uploadProgress = undefined
    this.uploadSub = undefined
    this.uploading = false
  }
}
