<h1 mat-dialog-title>{{"voucher.Dialog.ReleaseLink.Title" | translate}}:
  {{this.data.reference}}
</h1>
<div mat-dialog-content>
  @if (reloading) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
  <div class="flex flex-row justify-center items-center content-center gap-2">
    <mat-form-field class="grow shrink basis-auto">
      <mat-label>{{'voucher.Dialog.ReleaseLink.Label' | translate}}</mat-label>
      <input matInput [value]="link" readonly>
    </mat-form-field>
    <button mat-flat-button [cdkCopyToClipboard]="link" class="grow shrink basis-auto" [disabled]="link.length <= 0">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-actions>
  <div class="flex flex-row justify-center mh-100 gap-2">
    <button mat-button mat-stroked-button color="primary" (click)="onReleaseClick()" class="grow shrink basis-auto" [disabled]="reloading">
      <mat-icon>move_to_inbox</mat-icon>
    {{"voucher.Dialog.Action.Release" | translate}}</button>
    <button mat-button mat-stroked-button color="warn" (click)="onCancelClick()" class="grow shrink basis-auto" [disabled]="reloading">
      <mat-icon>cancel</mat-icon>
    {{"action.Cancel" | translate}}</button>
  </div>
</div>
