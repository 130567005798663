@if (service.company) {
<lib-user-board-header [companyId]="companyId()"></lib-user-board-header>
}
@if (this.service.reloading | async) {
<mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
<div class="flex flex-col px-3 gap-2 pt-2">
  @if (service.company) {
  <lib-user-board-tabs></lib-user-board-tabs>
  }
</div>