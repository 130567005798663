<mat-toolbar>
  <div class="flex flex-col flex-auto">
    <div class="flex flex-row justify-start mh-100 gap-4 items-center">
      <mat-form-field appearance="outline" class="grow shrink basis-auto dense-1" subscriptSizing="dynamic">
        <mat-label>Filter</mat-label>
        <input matInput placeholder="Search columns" [(ngModel)]="filter" (keyup)="onKeyUp(searchText.value)"
          #searchText>
      </mat-form-field>
      <button mat-icon-button (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
@if (reloading) {
<mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
<lib-board>
  <div class="board flex flex-col justify-center mw-100 gap-2">
    <mat-card class="board-card">
      <div class="flex flex-col justify-center mw-100 gap-2">


        <table mat-table [dataSource]="dataSource" class="grow shrink basis-auto">
          <ng-content></ng-content>
          <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns();"></tr>
        </table>

        <mat-paginator [length]="resultsLength" [pageSize]="pageSize" class="grow shrink basis-auto"></mat-paginator>
      </div>
    </mat-card>
  </div>
</lib-board>