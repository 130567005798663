import { Directive } from "@angular/core"


@Directive({ selector: 'lib-header-toolbar-actions' })
export class HeaderToolbarActions {
}

@Directive({ selector: 'lib-header-toolbar-content' })
export class HeaderToolbarContent {
}

@Directive({ selector: 'lib-header-toolbar-icon' })
export class HeaderToolbarIcon {
}

@Directive({ selector: 'lib-header-toolbar-filter' })
export class HeaderToolbarFilter {
}
