import { Component, model, output } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatIcon } from '@angular/material/icon'
import { TranslatePipe } from '@ngx-translate/core'

@Component({
  selector: 'lib-edit-save-actions',
  imports: [MatIcon, TranslatePipe, MatCardModule, MatButtonModule],
  templateUrl: './edit-save-actions.component.html',
  styleUrl: './edit-save-actions.component.scss',
})
export class EditSaveActionsComponent {
  editMode = model(false)

  saveAction = output<void>()

  toggleEdit() {
    this.editMode.update(edit => !edit)
  }

  save() {
    this.saveAction.emit()
    this.toggleEdit()
  }
}
