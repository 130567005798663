<lib-header-toolbar [title]="company?.name">
  <lib-header-toolbar-actions>
    <button mat-icon-button [matMenuTriggerFor]="manage">
      <mat-icon>more_vert</mat-icon>
    </button>
  </lib-header-toolbar-actions>
</lib-header-toolbar>

<lib-loading-bar [loading]="reloading" />
<div class="flex flex-row flex-wrap px-3 gap-2 pt-2">
  <lib-company-details-profile [data]="company" style="flex: 1 1 calc(50% - 0.5rem)"></lib-company-details-profile>
  <lib-company-details-contact [data]="company" style="flex: 1 1 calc(50% - 0.5rem)"></lib-company-details-contact>
</div>

<mat-menu #manage="matMenu">
  <button mat-menu-item (click)="edit()">
    <mat-icon>edit</mat-icon>
    {{'site.rename' | translate}}
  </button>
</mat-menu>