/** @deprecated use specific roles instead */
export const ADMIN = 'ADMIN'
/** @deprecated use specific roles instead */
export const MANAGER = 'MANAGER'
/** @deprecated use specific roles instead */
export const DATA_EXPORTER = 'DATA_EXPORTER'

export const ENTITLEMENT_READ = "entitlement.read"
export const ENTITLEMENT_WRITE = "entitlement.write"
export const ENTITLEMENT_ADMIN = "entitlement.admin"

export const EQUIPMENT_READ = "equipment.read"
export const EQUIPMENT_WRITE = "equipment.write"
export const EQUIPMENT_ADMIN = "equipment.admin"
export const EQUIPMENT_OPERATE = "equipment.operate"
export const EQUIPMENT_MAINTAIN = "equipment.maintain"
export const EQUIPMENT_EXEMPT = "equipment.exempt"

export const COMPANY_READ = "company.read"
export const COMPANY_WRITE = "company.write"
export const COMPANY_ADMIN = "company.admin"

export const SITE_READ = "site.read"
export const SITE_WRITE = "site.write"
export const SITE_ADMIN = "site.admin"

export const SYSTEM_READ = "system.read"
export const SYSTEM_WRITE = "system.write"
export const SYSTEM_ADMIN = "system.admin"
export const SYSTEM_OPERATION = "system.operate"

export const SHARING_READ = "share.read"
export const SHARING_WRITE = "share.write"
export const SHARING_ADMIN = "share.admin"

export const REGISTRATION_CREATE = "registration.create"

export const DOCUMENT_READ = "image.read"
export const DOCUMENT_WRITE = "image.write"

export const IMAGE_READ = "image.read"
export const IMAGE_WRITE = "image.write"

export const NOTE_READ = "image.read"
export const NOTE_WRITE = "image.write"

export const ACCOUNTING_ACCOUNT_ADMIN = "accounting.account.admin"
export const ACCOUNTING_ACCOUNT_READ = "accounting.account.read"

export const ACCOUNTING_CHARGE_ADMIN = "accounting.charge.admin"
export const ACCOUNTING_CHARGE_READ = "accounting.charge.read"

export const ACCOUNTING_LICENSE_ADMIN = "accounting.license.admin"
export const ACCOUNTING_LICENSE_READ = "accounting.license.read"

export const ACCOUNTING_MODEL_ADMIN = "accounting.model.admin"
export const ACCOUNTING_MODEL_READ = "accounting.model.read"

export const ACCOUNTING_PERMISSION_ADMIN = "accounting.permission.admin"
export const ACCOUNTING_VOUCHER_ADMIN = "accounting.voucher.admin"

export const PROCESS_READ = "process.read"
export const PROCESS_WRITE = "process.write"
export const PROCESS_ADMIN = "process.admin"

export const BETA_ACCESS = "beta.access"
export const PUBLIC_ACCESS = "public"

export const COMMERCE_READ = "commerce.read"
export const COMMERCE_WRITE = "commerce.write"
export const COMMERCE_ADMIN = "commerce.admin"

export const ACCOUNTING_ADMIN = "accounting.admin"
export const SEARCH_ADMIN = "search.admin"
export const FEEDBACK_ADMIN = "feedback.admin"
export const SALES_ADMIN = "sales.admin"
export const CUSTOMER_ADMIN = "customer.admin"
export const STOCKLEVEL_IMPORT = "commerce.stocklevel.import"
export const INGEST_ADMIN = "ingest.admin"
export const INGEST_ADMIN_CMS = "ingest.admin.cms"
export const INGEST_ADMIN_WBITZER = "ingest.admin.wbitzer"
export const INGEST_ADMIN_WEBPAGE = "ingest.admin.webpage"
export const DOCUMENT_ADMIN = "document.admin"
export const KB_EQUIPMENT_READ = "knowledge.equipment.read"
export const KB_EQUIPMENT_ADMIN = "knowledge.equipment.admin"

export const SALES_ORDER_CUSTOMER = "order.customer"
export const SALES_ORDER_SUBSIDIARY = "order.subsidiary"
export const SALES_ORDER_HUB = "order.hub"
export const SALES_ORDER_ADMIN = "order.admin"

export const PRODUCT_CONFIG_READ = "knowledge.pid.read"
export const PRODUCT_CONFIG_ADMIN = "knowledge.pid.admin"

export const THING_TEMPLATE_ADMIN = "thing-template.admin"
export const PRODUCTION_WEEK_IMPORT = "production-week.admin"
export const ASSET_ADMIN = "asset.admin"

export const ACTIVITY_READ = 'activity.read'
export const ACTIVITY_WRITE = 'activity.write'

export const PERMISSION_WISHLIST_READ = "wishlist.read"
export const PERMISSION_WISHLIST_WRITE = "wishlist.write"
export const PERMISSION_WISHLIST_ADMIN = "wishlist.admin"
