import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog"
import { debounceTime, finalize, switchMap, tap } from "rxjs/operators"
import { UntypedFormControl, ReactiveFormsModule } from "@angular/forms"
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from "@angular/material/autocomplete"

import { CdkScrollable } from '@angular/cdk/scrolling'

import { MatProgressBar } from '@angular/material/progress-bar'
import { MatFormField, MatLabel } from '@angular/material/form-field'
import { MatInput } from '@angular/material/input'
import { MatOption } from '@angular/material/core'
import { MatList, MatListItem } from '@angular/material/list'
import { MatIcon } from '@angular/material/icon'
import { MatButton } from '@angular/material/button'
import { TranslatePipe } from '@ngx-translate/core'
import { MailReceiver, Voucher } from '../voucher.api'
import { Customer, CustomerService } from '../../../customer'
import { VoucherService } from '../voucher.service'

@Component({
  selector: 'lib-release-voucher-by-mail-dialog',
  templateUrl: './release-voucher-by-mail-dialog.component.html',
  styleUrls: ['./release-voucher-by-mail-dialog.component.scss'],
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatProgressBar, MatFormField, MatLabel, MatInput, MatAutocompleteTrigger, ReactiveFormsModule, MatAutocomplete, MatOption, MatList, MatListItem, MatIcon, MatDialogActions, MatButton, TranslatePipe]
})
export class ReleaseVoucherByMailDialogComponent implements OnInit {

  searchCustomerCtrl = new UntypedFormControl()
  reloading = false
  searchResult: Customer[] = []
  selectedCustomer: Customer[] = []
  errorMsg = ""

  constructor(
    public dialogRef: MatDialogRef<ReleaseVoucherByMailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Voucher,
    private readonly customerService: CustomerService,
    private readonly service: VoucherService
  ) {
  }

  ngOnInit(): void {
    this.searchCustomerCtrl.valueChanges.pipe(
      debounceTime(500),
      tap(() => {
        this.searchResult = []
        this.errorMsg = ""
        this.reloading = true
      }),
      switchMap(searchTerm => this.customerService.findByEmail(searchTerm)
        .pipe(
          finalize(() => {
            this.reloading = false
          }),
        ))
    ).subscribe(data => {
      this.searchResult = data
    })
  }


  onCancelClick() {
    this.dialogRef.close()
  }

  onReleaseClick() {
    this.reloading = true
    const receiver: MailReceiver[] = this.selectedCustomer.map(c => new MailReceiver(c.name, c.email))
    this.service.releaseVoucherByMail(this.data.id, receiver).subscribe(() => this.dialogRef.close())
  }

  private handleSearchResult(result: Array<Customer>) {
    this.selectedCustomer = result
  }

  handleUserSelect(event: MatAutocompleteSelectedEvent) {
    const user: Customer = event.option.value
    if (user) {
      this.selectedCustomer.push(user)
      this.searchCustomerCtrl.setValue('')
    }
  }
}
