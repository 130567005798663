export class VoucherCashRequest {
  constructor(
    public userId: number,
    public accountId: number,
    public hash: string
  ) {
  }
}

export class VoucherCreateRequest {
  constructor(
    public reference: string,
    public amount: number,
    public originatorId: number,
    public originatorAccountId: number
  ) {
  }
}

export class VoucherRejectRequest {
  constructor(
    public userId: number,
    public accountId: number,
  ) {
  }
}

export class VoucherUpdateRequest {
  constructor(
    public reference: string,
    public amount: number,
  ) {
  }
}

export interface Voucher {
  id: string,
  reference: string,
  amount: number,
  originatorId: string,
  originatorAccountId: string,
  status: string
}

export class MailReceiver {
  constructor(
    public name: string,
    public email: string,
  ) {
  }
}
