export interface Invitation {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  companyId: number,
  timestamp: string,
  status: string
}

export class InviteRequest {
  constructor(
    public firstName: string,
    public lastName: string,
    public email: string
    ) {
  }
}
