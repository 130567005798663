import { Component, input, OnInit } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { MatDialog } from "@angular/material/dialog"
import { MatFormField, MatLabel } from '@angular/material/form-field'
import { MatInput } from '@angular/material/input'
import { MatPaginator, PageEvent } from "@angular/material/paginator"
import { MatSelect, MatSelectChange } from "@angular/material/select"
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable, MatTableDataSource } from "@angular/material/table"
import { TranslatePipe, TranslateService } from "@ngx-translate/core"
import { HotToastService } from "@ngxpert/hot-toast"
import { EMPTY, Subject } from "rxjs"
import { catchError, debounceTime, filter, switchMap, tap } from "rxjs/operators"

import { MatMiniFabButton } from '@angular/material/button'
import { MatOption } from '@angular/material/core'
import { MatIcon } from '@angular/material/icon'
import { MatProgressBar } from '@angular/material/progress-bar'

import { Customer, CustomerInfo, CustomerSearchRequest, CustomerService } from '..'
import { EntitlementService } from '../../entitlement'
import { Page } from '../../shared.api'
import { ConfirmDeleteDialogComponent } from '../customer/confirm-delete-dialog/confirm-delete-dialog.component'
import { CustomerChangeDialogComponent } from '../customer/customer-change-dialog/customer-change-dialog.component'

@Component({
  selector: 'lib-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
  imports: [MatFormField, MatLabel, MatInput, MatProgressBar, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatSelect, MatOption, MatMiniFabButton, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, TranslatePipe]
})
export class CustomerListComponent implements OnInit {
  companyId = input<string | undefined>()
  displayedColumns = ['name', 'email', 'level', 'role', 'subscription', 'action']
  dataSource: MatTableDataSource<CustomerInfo> = new MatTableDataSource()

  pageSize = 10
  pageIndex = 0
  totalSize = -1
  reloading = false
  filter = ""
  private subject: Subject<string> = new Subject()

  constructor(
    private readonly entitlementService: EntitlementService,
    private readonly service: CustomerService,
    private readonly dialog: MatDialog,
    private readonly toast: HotToastService,
    private readonly translate: TranslateService
  ) {
    this.subject.pipe(
      debounceTime(1000),
      switchMap((searchTextValue: string) => {
        this.filter = searchTextValue
        return this.reload()
      }),
      takeUntilDestroyed()
    ).subscribe()
  }

  ngOnInit() {
    this.reload().subscribe()
  }

  handlePaginatorEvent(event: PageEvent) {
    this.loadData(event.pageIndex, event.pageSize).subscribe()
  }

  reload() {
    return this.loadData(0, this.pageSize)
  }

  private loadData(page: number, size: number) {
    if (this.reloading) return EMPTY
    this.reloading = true
    const companyId = this.companyId() ? +this.companyId()! : undefined
    const request: CustomerSearchRequest = { fullTextSearch: this.filter, companyId }
    return this.service.searchCustomer(request, page, size).pipe(
      tap(d => this.handleData(d))
    )
  }

  private handleData(data: Page<CustomerInfo>) {
    this.dataSource.data = data.content

    this.pageIndex = data.pageable.number
    this.pageSize = data.pageable.size
    this.totalSize = data.totalSize
    this.reloading = false
  }

  onLevelChange(info: CustomerInfo, event: MatSelectChange) {
    if (!event.value) return
    this.entitlementService.setUserLevel(+info.id, event.value).pipe(
      switchMap(() => this.loadData(this.pageIndex, this.pageSize))
    ).subscribe()
  }

  onRoleChange(info: CustomerInfo, event: MatSelectChange) {
    const roles = event.value
    this.entitlementService.setRoles(+info.id, roles).pipe(
      switchMap(() => this.loadData(this.pageIndex, this.pageSize))
    ).subscribe()
  }

  onSubscriptionChange(info: CustomerInfo, event: MatSelectChange) {
    const types = event.value
    this.service.setSubscriptionTypes(+info.id, types).pipe(
      switchMap(() => this.loadData(this.pageIndex, this.pageSize))
    ).subscribe()
  }

  edit(customer: Customer) {
    const dialogRef = this.dialog.open(CustomerChangeDialogComponent, {
      width: '50%',
      data: customer
    })

    dialogRef.afterClosed().pipe(
      filter((sent: boolean) => sent),
      tap(() => this.toast.info(this.translate.instant('customer.action.changed'))),
      switchMap(() => this.loadData(this.pageIndex, this.pageSize)),
    ).subscribe()
  }

  delete(customer: Customer) {
    if (this.reloading) return

    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '50%',
      data: customer
    })

    dialogRef.afterClosed().pipe(
      filter((confirmed: boolean) => confirmed),
      switchMap(() => this.service.remove(customer.id)),
      tap(() => this.toast.info(this.translate.instant('customer.action.deleted'))),
      switchMap(() => this.loadData(0, this.pageSize)),
      catchError(err => {
        this.toast.error(this.translate.instant('customer.action.failed'))
        return this.loadData(0, this.pageSize)
      })
    ).subscribe()
  }

  applyFilter(value: string) {
    this.subject.next(value)
  }

}
