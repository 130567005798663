import {Component, Inject, OnInit} from '@angular/core'
import {TranslatePipe, TranslateService} from "@ngx-translate/core"
import {ReactiveFormsModule, FormGroup, FormControl, FormArray} from "@angular/forms"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInput} from "@angular/material/input"
import {
  MatDialogActions,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule
} from "@angular/material/dialog"
import {MatButton, MatIconButton} from "@angular/material/button"
import {MatCard} from "@angular/material/card"
import {MatIcon} from "@angular/material/icon"

@Component({
  selector: 'lib-dialog',
  imports: [MatDialogModule, TranslatePipe, ReactiveFormsModule, MatFormFieldModule, MatInput, MatDialogActions, MatButton, MatIcon, MatIconButton],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
})
export class DialogComponent implements OnInit {
  form: FormGroup = new FormGroup({})
  keys: string[] = []

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { object: any; title: string }
  ) {}

  ngOnInit(): void {
    this.keys = Object.keys(this.data.object)

    this.keys.forEach(key => {
      const value = this.data.object[key]

      if (Array.isArray(value)) {
        this.form.addControl(
          key,
          new FormArray(value.map(item => new FormControl(item)))
        )
      } else {
        this.form.addControl(key, new FormControl(this.data.object[key]))
      }
    })
  }

  getTranslation(key: string): string {
    return this.translate.instant(`${this.data.title}.${key}`) || key
  }

  getFormArray(key: string): FormArray {
    return this.form.get(key) as FormArray
  }

  getFormControl(control: any): FormControl {
    return control as FormControl
  }

  addArrayItem(key: string): void {
    this.getFormArray(key).push(new FormControl(''))
  }

  removeArrayItem(key: string, index: number): void {
    this.getFormArray(key).removeAt(index)
  }

  isFormArray(key: string): boolean {
    return this.form.get(key) instanceof FormArray
  }


  onSave(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value)
    }
  }

  onCancel(): void {
    this.dialogRef.close()
  }

  protected readonly FormArray = FormArray
  protected readonly FormControl = FormControl
}
