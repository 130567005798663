<div class="flex flex-col justify-center mw-100">
  @if (reloading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
  @if (dataProvider()) {
  <lib-board-list [provider]="dataProvider()" [displayedColumns]="displayedColumns">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{'id' | translate}}</th>
      <td mat-cell *matCellDef="let row"> {{row.license.id}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{'name' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <p>{{row.equipment.name}}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="serial">
      <th mat-header-cell *matHeaderCellDef>{{'license.table.SerialNr' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <p>{{row.equipment.serialNumber}}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef>{{'license.table.Active' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        @if (row.license.active) {
        <mat-icon>check_circle</mat-icon>
        }
        @if (!row.license.active) {
        <mat-icon>highlight_off</mat-icon>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef>{{'license.table.Model' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <button mat-button color="accent">{{row.model.title}}</button>
      </td>
    </ng-container>
    <ng-container matColumnDef="ain">
      <th mat-header-cell *matHeaderCellDef>{{'license.table.AIN' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <a mat-flat-button routerLink="/equipment/{{row.equipment.id}}">
          <mat-icon>link</mat-icon>
          Equipment
        </a>
      </td>
    </ng-container>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="6">{{'license.table.NoData' | translate}}</td>
    </tr>
  </lib-board-list>
  }
</div>