import { DatePipe } from '@angular/common'
import { Component } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { MatDialog } from "@angular/material/dialog"
import { MatDivider } from '@angular/material/divider'
import { MatPaginator, PageEvent } from "@angular/material/paginator"
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource, MatTableModule } from "@angular/material/table"
import { Page } from '@mybitzer-workspace/shared'
import { TranslatePipe } from '@ngx-translate/core'
import { HotToastService } from "@ngxpert/hot-toast"
import { EMPTY, switchMap, tap, timer } from "rxjs"
import { catchError } from 'rxjs/operators'
import { FileUploadDetailsDialogComponent } from "../file-upload-details-dialog/file-upload-details-dialog.component"
import { FileUploadLogEntry, Status } from '../file-upload/file-upload.api'
import { FileUploadService } from "../file-upload/file-upload.service"

@Component({
  selector: 'lib-file-upload-status',
  templateUrl: './file-upload-status.component.html',
  styleUrl: './file-upload-status.component.scss',
  imports: [MatTableModule, MatPaginator, DatePipe, TranslatePipe, MatSort, MatDivider]
})
export class FileUploadStatusComponent {

  reloading = false
  pageSize = 12
  pageIndex = 0
  totalSize = -1

  displayedColumns = ['start', 'end', 'filename', 'status', 'processedRows', 'errors']
  dataSource: MatTableDataSource<FileUploadLogEntry> = new MatTableDataSource()

  constructor(private service: FileUploadService, private toast: HotToastService, private dialog: MatDialog) {
    timer(0, 10000).pipe(
      switchMap(_ => this.reload(this.pageIndex, this.pageSize)),
      takeUntilDestroyed())
      .subscribe()
  }

  private reload(page: number, size: number) {
    if (this.reloading) return EMPTY

    this.reloading = true

    return this.service.search(page, size).pipe(
      tap({
        next: (value) => {
          if (value.body) {
            this.handleData(value.body.entries)
          }
        },
        error: (err) => this.handleError(err),
        complete: () => (this.reloading = false)
      }),
      catchError((err) => {
        this.handleError(err)
        return EMPTY
      })
    )
  }

  private handleData(d: Page<FileUploadLogEntry>) {
    this.dataSource.data = d.content
    this.pageSize = d.pageable.size
    this.pageIndex = d.pageable.number
    this.totalSize = d.totalSize
    this.reloading = false
  }

  private handleError(_err: any) {
    this.toast.error("Something went wrong")
    this.reloading = false
  }

  handlePaginatorEvent(event: PageEvent) {
    this.reload(event.pageIndex, event.pageSize)
  }

  statusColor(entry: FileUploadLogEntry): string {
    switch (entry.status) {
      case Status.PROCESSING:
        return "orange"
      case Status.DONE:
        return "green"
      case Status.FAILED:
        return "red"
    }
  }

  openErrors(entry: FileUploadLogEntry) {
    if (!entry) return
    this.dialog.open(FileUploadDetailsDialogComponent, {
      width: '50%',
      data: entry
    })
  }

}
