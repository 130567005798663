import { CdkScrollable } from '@angular/cdk/scrolling'
import { Component, Inject } from '@angular/core'
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms"
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog"


import { MatButton } from '@angular/material/button'
import { MatFormField, MatLabel } from '@angular/material/form-field'
import { MatInput } from '@angular/material/input'
import { TranslatePipe } from '@ngx-translate/core'
import { InviteRequest } from '../invite-api'
import { InviteService } from '../invite.service'

@Component({
  selector: 'lib-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.scss'],
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatDialogActions, MatButton, MatDialogClose, TranslatePipe]
})
export class InviteDialogComponent {
  form: FormGroup | undefined

  constructor(
    private fb: FormBuilder,
    private service: InviteService,
    private dialogRef: MatDialogRef<InviteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.form = this.fb.group({
      firstName: this.fb.control('', [Validators.required]),
      lastName: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [Validators.required]),
    })
  }

  sendInvitation() {
    if (!this.form || !this.form.valid) return

    const values = this.form.value
    const request = new InviteRequest(
      values.firstName,
      values.lastName,
      values.email
    )
    this.service.inviteToCompany(this.data.id, request).subscribe({
      next: value => {
        if (!value) {
          this.dialogRef.close(false)
          return
        }
        this.dialogRef.close(true)
      },
      error: err => this.dialogRef.close(false)
    })
  }
}
