import { AsyncPipe } from '@angular/common'
import { Component, input, OnInit } from '@angular/core'
import { MatProgressBar } from '@angular/material/progress-bar'
import { UserBoardHeaderComponent } from '../user-board-header/user-board-header.component'
import { UserBoardTabsComponent } from '../user-board-tabs/user-board-tabs.component'
import { UserBoardService } from "../user-board.service"

@Component({
  selector: 'lib-user-board',
  templateUrl: './user-board.component.html',
  styleUrls: ['./user-board.component.scss'],
  imports: [UserBoardHeaderComponent, MatProgressBar, UserBoardTabsComponent, AsyncPipe]
})
export class UserBoardComponent implements OnInit {
  companyId = input<string | undefined>()

  constructor(public service: UserBoardService) {
  }

  ngOnInit() {
    this.service.reload(this.companyId()).subscribe()
  }

}
