<div class="flex flex-col">

  <mat-form-field subscriptSizing="dynamic">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter(filter.value)" #filter placeholder="" #input (keyup.enter)="reload()">
  </mat-form-field>
  @if (reloading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }

  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{'name' | translate}}</th>
      <td mat-cell *matCellDef="let info">{{info.name}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> {{'contactMail' | translate}}</th>
      <td mat-cell *matCellDef="let info">{{info.email}}</td>
    </ng-container>

    <ng-container matColumnDef="level">
      <th mat-header-cell *matHeaderCellDef> {{'userLevel' | translate}}</th>
      <td mat-cell *matCellDef="let info">
        <mat-form-field appearance="outline" class="dense-1" subscriptSizing="dynamic">
          <mat-select [(value)]="info.entitlement.userLevel" (selectionChange)="onLevelChange(info, $event)">
            <mat-option value="INFO">{{'userLevel_info' | translate}}</mat-option>
            <mat-option value="LIMITED">{{'userLevel_limited' | translate}}</mat-option>
            <mat-option value="FULL">{{'userLevel_full' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef> {{'userRoles' | translate}}</th>
      <td mat-cell *matCellDef="let info">
        <mat-form-field appearance="outline" class="dense-1" subscriptSizing="dynamic">
          <mat-select [(value)]="info.entitlement.roles" multiple (selectionChange)="onRoleChange(info, $event)">
            <mat-option value="SERVICE_USER">{{'userRole_service' | translate}}</mat-option>
            <mat-option value="POWER_USER">{{'userRole_power' | translate}}</mat-option>
            <mat-option value="BILLING_USER">{{'userRole_billing' | translate}}</mat-option>
            <mat-option value="WEBSHOP_USER" disabled>{{'userRole_webshop' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="subscription">
      <th mat-header-cell *matHeaderCellDef> {{'userSubscriptions' | translate}}</th>
      <td mat-cell *matCellDef="let info">
        <mat-form-field appearance="outline" class="dense-1" subscriptSizing="dynamic">
          <mat-select [(value)]="info.subscription.types" multiple
            (selectionChange)="onSubscriptionChange(info, $event)">
            <mat-option value="FAULT_NOTIFICATION">{{'userSubscription_notifications' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let info">
        <div class="flex flex-row gap-2 justify-center">
          <button mat-mini-fab color="primary" (click)="edit(info)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-mini-fab color="primary" (click)="delete(info)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header"></tr>
    <tr mat-row *matRowDef="let info; columns: displayedColumns;" class="hover-effect"></tr>

  </table>

  <mat-paginator [length]="totalSize" [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="[5, 10, 25, 100]"
    showFirstLastButtons (page)="handlePaginatorEvent($event)">
  </mat-paginator>
</div>