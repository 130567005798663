import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { AuthService } from './auth.service'

@Directive({
  selector: '[libHasRole]',
  standalone: true
})
export class HasRoleDirective implements OnInit {

  @Input('libHasRole') hasRole: string[] = []  // Required roles
  @Input() matchAll = false           // Whether all roles must match

  constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef,
    private readonly authService: AuthService
  ) { }

  ngOnInit() {
    this.updateView()
  }

  private updateView() {
    const hasRequiredRoles = this.matchAll
      ? this.hasRole.every(r => this.authService.hasRole(r))
      : this.hasRole.some(r => this.authService.hasRole(r))

    if (hasRequiredRoles) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    } else {
      this.viewContainer.clear()
    }
  }
}
