export interface Pageable {
  offset: number;
  number: number;
  size: number;
}


export interface Page<T> {
  content: T[];
  pageable: Pageable;
  totalPages: number;
  totalSize: number;
  first: boolean;
  last: boolean;
  empty: boolean;
}

export class PatchRequest<T> {
  constructor(
    public value: T
  ) {

  }

}

export interface LocaleValue {
  values: Map<string, string>
}
